import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConditionalLink, Component, Popup, Modal, UniversalLink, Icon, Toast } from '@plone/volto/components';
import { getBaseUrl, flattenToAppURL, getContentIcon } from '@plone/volto/helpers';
import LouverIcon from '../../../../icons/Louver.png';
import DiffuserIcon from '../../../../icons/Diffuser.png';
import { toast } from 'react-toastify';

import { Container, Image, Button, Header, List, ListItem, Table } from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { getContent } from '@plone/volto/actions';
import { getCalculator } from '../../../../../actions';
import { useSessionStorage } from '../../../../../helpers';
import { SelectionTable, SelectionStorage } from '../../../../../selection/table';
import { GeneratePdfForm } from '../../../../../selection/document';
import { Acoustic } from '../../../../../selection/acoustic';
import { RelatedItems, ContentLink } from '../../../../../selection/relateditems';
import { trackEvent } from '@eeacms/volto-matomo/utils';
import moment from 'moment';


class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputSubmit = this.handleInputSubmit.bind(this);
    }

    handleInputChange(event) {    
        const target = event.target;
        const value = target.value;
        const name = target.name;
        trackEvent({category: 'performance', action: "louver", name: name, value: value});
        this.props.setPerformance(
            {
             ...this.props.performance,
             [name]: value
            }
        );
    }

    handleInputSubmit(event) {
        const options = {category: 'calculate', action: 'width=' + this.props.performance.width + ' height=' + this.props.performance.height + ' volume=' + this.props.performance.volume };
        // trackEvent(options); 
        event.preventDefault();
    }

    render() {
        return (
            <form className="calculator" onSubmit={this.handleInputSubmit}>       
                <label>Width: <DebounceInput debounceTimeout={500} type="number" name="width" value={this.props.performance.width || ''} onChange={this.handleInputChange} />mm</label>
                <label>Height: <DebounceInput debounceTimeout={500} type="number" name="height" value={this.props.performance.height || ''} onChange={this.handleInputChange} />mm</label>
                <label>Volume: <DebounceInput debounceTimeout={500} type="number" name="volume" value={this.props.performance.volume || ''} onChange={this.handleInputChange} />l/s</label>
            </form>
        );
    }
}


const ListContent = ({ onClose, item, calculator }) => {
    let orientations = new Set(['Horizontal', 'Vertical']);
    let features = item.Subject.filter(i => (! orientations.has(i)));
    const options = {category: item['@id'], action: 'view-more'};
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')){
        console.log(calculator?.calculator);
    }
    trackEvent({category: 'performance', action: "info", name: item?.title}); 
    return (
        <div className="ui page modals dimmer transition visible active">
            <div className="ui modal transition visible active modal">
                <div className="actions">
                    <Button className="ui basic circular floated button orange" aria-label="Close" title="close" onClick={onClose}>Close</Button>
                </div>
                <div className="ui header">
                    <h1 className="center">{item.title}</h1>
                </div>
                <div className="content popup">
                    <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                    <div className="inside-popup">
                        <h1 className="louverdesc">{item.description}</h1>
                        <p>Overview: {calculator?.overview}</p>
                        <Header dividing sub>Orientation</Header>
                        <List items={ item.Subject.filter(i => orientations.has(i)) } />
                        {features.length > 0 && (
                          <>
                            <Header dividing sub>Louver features</Header>
                            <List items={ features } />
                          </>
                        )}
                        <Acoustic calculator={calculator?.calculator}/>

                        {calculator?.documents.length > 0 && (
                          <>
                              <List>
                          {calculator.documents.map((doc) => (
                              <ListItem key={doc['@id']}>
              
                                  <ContentLink doc={doc} />
                              </ListItem>
                          ))}
                              </List>
                          </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}


function Intake({ calculator }){
    const intake = calculator?.pressure_drop?.intake;
    if ( intake > calculator?.pressure_drop_max?.intake ){
        const pressure = "pressure exceeds permissible levels (" + calculator?.pressure_drop_max?.intake + " Pa) as " + calculator?.limits?.pressure_limitation;
        return (
            <>
                    <strong className="warn">{ intake } Pa</strong>
                    <Popup content={ pressure } trigger={<Button className="ui button warn">Limitation</Button>} /> 
            </>
    )}
    return ( <> { intake || '-' } Pa </> )
}

function AreaVelocity( { calculator }){
    if ( calculator?.louver_velocity > calculator?.limits?.velocity_max ){
        const velocity = "velocities above " + calculator?.limits?.velocity_max + "m/s could incur water carryover and increased noise";
        return (
            <>
                    <strong className="warn">{ calculator?.louver_velocity || '-' } m/s</strong>
                    <Popup content={ velocity } trigger={<Button className="ui button warn">Limitation</Button>} /> 
            </>
        )
    }
    return (
        <> { calculator?.louver_velocity || '-' } m/s </>
    )
}


function ShowCalculation({ performance, calculator, item_id }) {
    let perf = '-';
    let vol = '-';
    let table_dimension = 'listing performance';
    if (performance.width && performance.height){
        perf = performance.width + ' mm x ' + performance.height + ' mm';
        if (performance.volume){
            table_dimension = 'listing performance volume';
        } else {
            table_dimension = 'listing performance size';
        }

    }
    if (performance.volume){
        vol = performance.volume + ' l/s';
    }
    let efficiency_heading = 'Efficiency Class:';
    let efficiency = calculator?.efficiency_class || '-';
    if ( ! calculator?.efficiency_class && calculator?.water_penetration ){
        efficiency_heading = 'Water Penetration:';
        efficiency = calculator?.water_penetration + ' m/s';
    }
    if ( calculator?.errors ) {
        return (
            <>
                <h4 className="performance table">Performance Table</h4>
                <div className="top-list listing-performance">
                    <table className="listing performance">
                        <tbody>
                            <tr><td className="heading">Nominal Size (w x h):</td><td className="result">{ calculator.errors }</td></tr>
                            <tr><td className="heading">Airflow:</td><td className="result">{ vol }</td></tr>
                            <tr><td className="heading">{ efficiency_heading }</td><td className="result">{ efficiency }</td></tr>
                            <tr><td className="heading">Wind Loading:</td><td className="result">{ calculator?.wind_loading || '-' }</td></tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }


    const exhaust = calculator?.pressure_drop?.exhaust;
    const intake = calculator?.pressure_drop?.intake;
    return (
        <>
            <h4 className="performance table">Performance Table</h4>
            <div className="top-list listing-performance">
                <table className={ table_dimension }>
                    <thead>
                        <tr><th className="heading">Nominal Size (w x h):</th><th className="result">{ perf }</th></tr>
                        <tr><th className="heading">Airflow:</th><th className="result">{ vol }</th></tr>
                    </thead>
                    <tbody>
                        { (calculator?.width_sections * calculator?.height_sections) > 1 && (
                        <tr><td className="heading">Nominal Section ({calculator?.width_sections} x {calculator?.height_sections}) Size:</td><td className="result">{ calculator.section_width + ' mm x ' + calculator.section_height + ' mm' }</td></tr>
                        )}
                        { performance.volume > 0.0 && (
                            <>
                                <tr><td className="heading">Free Area Velocity:</td>
                                    <td className="result"><AreaVelocity calculator={calculator} /></td>
                                </tr>
                                <tr><td className="heading">Pressure Drop (intake):</td><td className="result"><Intake calculator={calculator} /></td></tr>
                                <tr><td className="heading">Pressure Drop (exhaust):</td><td className="result">{ exhaust || '-' } Pa</td></tr>
                            </>)
                        }
                        { performance?.width && performance?.height && (
                            <>
                                <tr><td className="heading">Free Area:</td><td className="result">{ calculator?.pressure_area || '-' } m<sup>2</sup></td></tr>
                                <tr><td className="heading">Free Area:</td><td className="result">{ calculator?.free_area || '-' } %</td></tr>
                                <tr><td className="heading">Estimated Weight:</td><td className="result">{ calculator?.unit_weight || '-' } kg</td></tr>
                            </>
                        )}
                                <tr><td className="heading">{ efficiency_heading }</td><td className="result">{ efficiency }</td></tr>
                                <tr><td className="heading">Wind Loading:</td><td className="result">{ calculator?.wind_loading || '-' }</td></tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}    

function AddSelection({selection, setSelection, calculator, model}) {
    const searchParams = new URLSearchParams(document.location.search)
    function addToSelection() {
        toast.success(
      <Toast
        success
        title={"This item has been added to your selection list"}
        content=""
      />,
    );
    
    trackEvent({category: 'selection', action: "add", name: model});

    setSelection({...selection,
                  items:{...selection?.items,
                         'louver':[...selection?.items?.louver,
             {id: selection?.items?.louver.length + 1,
              amount: 1,
              reference: '',
              model: model,
              nominal_size: calculator?.calculator?.nominal_size,
              sections: calculator?.calculator?.sections,
              flow_rate: flow_rate,
              free_area_velocity: free_area_velocity,
              pressure_drop_intake: pressure_drop_intake,
              pressure_drop_exhaust: pressure_drop_exhaust,
              free_area: free_area,
              estimated_weight: estimated_weight,
              efficiency: calculator?.calculator?.efficiency,
              wind_loading: (calculator?.calculator?.wind_loading || '-')
             }]}})
    }
    if (searchParams.get('debug')){
        console.log(selection, calculator);
    }
    var estimated_weight = '';
    if ( calculator?.calculator?.unit_weight ){
         estimated_weight = calculator?.calculator?.unit_weight + ' kg';
    }
    var free_area_velocity = '';
    if ( calculator?.calculator?.louver_velocity ){
         free_area_velocity = calculator?.calculator?.louver_velocity + ' m/s';
    }
    var flow_rate = '';
    if ( calculator?.calculator?.flow_rate ){
         flow_rate = calculator?.calculator?.flow_rate + ' l/s';
    }
    var pressure_drop_intake = '';
    if ( calculator?.calculator?.pressure_drop?.intake ){
         pressure_drop_intake = calculator?.calculator?.pressure_drop?.intake + ' Pa';
    }
    var pressure_drop_exhaust = '';
    if ( calculator?.calculator?.pressure_drop?.exhaust ){
         pressure_drop_exhaust = calculator?.calculator?.pressure_drop?.exhaust + ' Pa';
    }
    var free_area = '';
    if ( calculator?.calculator?.free_area ){
         free_area = calculator?.calculator?.free_area + ' %';
    }
    return (
            <Button className="ui button add orange" title="Add item to selection" onClick={ addToSelection }>+</Button>
    )
}


function ShowMore({item, calculator}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button className="ui button add blue" onClick={() => setShowModal(true)}>View More</Button>
            {showModal && createPortal(

                <ListContent item={item} calculator={calculator} onClose={() => setShowModal(false)} />,
                document.body
            )}
        </>
    )
}

const LouverTemplate = ({ items, linkTitle, linkHref, isEditMode, popup }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';
  
    if (isInternalURL(href)) {
        link = (
            <ConditionalLink className="ui raised segments modal" to={flattenToAppURL(href)} condition={!isEditMode}>
            {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a className="ui raised segments modal" href={href}>{linkTitle || href}</a>;
    }
    const token = useSelector((state) => state.userSession.token, shallowEqual);

    const [performance, setPerformance] = useSessionStorage('performance', {width: undefined, height: undefined, volume: undefined});
    const [selection, setSelection] = SelectionStorage();

    const dispatch = useDispatch();
    const calculator = useSelector((state) => state.calculator?.result);
    const uids = items.map(item => item.UID);
  
    const prefs = { ...performance,
                   uids: uids}
    const location = useLocation();
    useEffect(() => {
        dispatch(
            getCalculator(location.pathname, prefs));
    }, [dispatch, performance]);
  
    return (
        <>
            <NameForm performance={ performance } setPerformance={ setPerformance } />
            <SelectionTable selection={ selection } setSelection={ setSelection } />
            <div className="items summaryplus modals active visible dimmable dimmed">
                {items.map((item) => (
                    <div className="ui listing-body left three item-list" key={item['@id']}>
                        <div className="top-list listing-image">
                            <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                        </div>
                        <div className="top-list listing-contents">
                        {token? (<a href={flattenToAppURL(item['@id'])}><h2 className="orange text heading underline">{item.title || item.id}</h2></a> ): (
                            <h2 className="blue text heading">{item.title || item.id}</h2> ) }
                        <h3 className="product desc blue text">{item.description}</h3>
                        </div>
                        <ShowCalculation performance={ performance } calculator={ calculator?.[item.UID]?.calculator } item_id={ item['@id'] } />
                        <Acoustic calculator={calculator?.[item.UID]?.calculator}/>
                        <RelatedItems related={ calculator?.[item.UID]?.related } />
                        <div className="top-list listing-button">
                        <AddSelection selection={ selection } setSelection={ setSelection } calculator={ calculator?.[item.UID] } model={ item?.title } />
                        <ConditionalLink key={item.UID} className="ui button add blue notanon" title="Edit"
                            condition={!isEditMode} href={flattenToAppURL(item['@id'] + '/edit')}>Edit</ConditionalLink>
                        <GeneratePdfForm item={ item } calculator={ calculator?.[item.UID] } performance={ performance } selection={selection} setSelection={setSelection} />
                        <ShowMore item={ item } calculator={ calculator?.[item.UID] } />
                        </div>
                    </div>
                ))}
            </div>
            {link && <div className="footer">{link}</div>}
        </>
    );
};

LouverTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default LouverTemplate;
