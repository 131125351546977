import { useState, useEffect } from 'react';
import { Button, Header, List, Table } from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';
import { createPortal } from 'react-dom';
import { usePDF, PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ShowCalculation } from "table";
import Select from 'react-select';
import moment from 'moment';
import holyoakeLogo from '../selection/Holyoake_logo.png';
import blueBanner from '../selection/BlueBanner.png';
import { getTld } from '../siteurl';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { trackEvent } from '@eeacms/volto-matomo/utils';


Font.register({ fontFamily: 'Helvetica', variants: 'thin' });
Font.register({ fontFamily: 'Helvetica-Bold' });
Font.register({ fontFamily: 'Helvetica-Oblique' });
Font.registerHyphenationCallback(word => (
    [word]
));

const styles = StyleSheet.create({
  page: {
      flexDirectionx: 'row',
      fontFamily: 'Helvetica',
      fontWeight: 200,
      fontSize: 10,
      paddingTop: 15,
      paddingLeft: 20,
      paddingBottom: 12,
      paddingRight: 20,
      backgroundColor: '#fff',
  },
  section: {
      margin: 10,
      padding: 10,
      flexGrowx: 1,
      display: 'block',
  },
  super_script: {
      fontSize: 6,
      top: -2,
      lineHeight: '50%',
      position: 'relative',
      verticalAlign: 'text-top',
      flexDirection: 'row',
      display: 'flex',
      textAlign: 'top',
  },
  logo: {
      position: 'absolute',
      right: 0,
      top: 20,
      width: 140,
  },
  h1: {
      color: '#ff5500',
      fontFamily: 'Helvetica',
      fontWeight: 100,
      fontSize: 58,
      paddingBottom: 5,  
      width: '70%',
  },
  description: {
      color: '#515355',
      fontWeight: 200,
      fontSize: 17,
      fontWeight: 100,
      letterSpacing: '-1px',
      textTransform: 'uppercase',
  },
  banner: {
      objectFit: 'fill',
      height: '75%',
      paddingLeft: 20,
      width: '45%',
  },
  leftside: { 
      flexDirection: 'column', 
      width: "50%", 
      color: '#fff', 
      marginRight: 14, 
      marginBottom: 20, 
      paddingTop: 5,
      paddingLeft: 34,
      paddingRight: 34,
      position: 'absolute',
      left: 0,
      lineHeight: 1.5,
  },
  h2Left: {
      color: '#fff',
      fontSize: 12,
      letterSpacing: '-0.5px',
      paddingBottom: 10,
      paddingTop: 10,
      textTransform: 'uppercase',
  },
  performance: {
      /* color: '#515355', */
  },
  h2Head: {
      color: '#0061c6',
      fontSize: 12,
      letterSpacing: '-0.5px',
      lineHeight: 1,
      paddingTop: 8,
      textTransform: 'uppercase',
  },
  h2: {
      color: '#0061c6',
      fontSize: 12,
      letterSpacing: '-0.5px',
      paddingBottom: 8,
      paddingTop: 10,
      textTransform: 'uppercase',
  },
  h2Warn: {
      color: '#0061c6',
      fontSize: 11,
      letterSpacing: '-0.5px',
      paddingBottom: 6,
      paddingTop: 8,
      textTransform: 'uppercase',
  },
  project: {
      color: '#0061c6',
      fontSize: 12,
      paddingBottom: 2,
      letterSpacing: '-0.5px',
      textTransform: 'uppercase',
  },    
  list_element: {
      fontSize: 10,
      paddingLeft: 0,
      paddingBottom: 3,
  },
  acoustic: {
      color: '#4a4b4c',
      border: '1px solid #000',
      lineHeight: 2,
  },
  footdate: {
      textAlign: 'right', 
      color: '#4a4b4c', 
      fontSize: 8, 
      paddingTop: 8,
  }
});

const offices = {
    nz: [{name: "Auckland",
          phone: "09 274 4144",
          email: "auckland@holyoake.com"},
         {name: "Wellington", 
          phone: "04 232 2722",
          email: "wellington@holyoake.com"},
         {name: "Christchurch",
          phone: "03 366 6545",
          email: "christchurch@holyoake.com"}
        ],
    au: [{name: "Melbourne",
          phone: "03 9551 5022",
          email: "melbourne@holyoake.com"},
        {name: "Sydney",
         phone: "02 9620 9555",
         email: "sydney@holyoake.com"},
        {name: "Brisbane",
         phone: "07 3807 7111",
         email: "brisbane@holyoake.com"},
        {name: "Adelaide",
         phone: "08 8354 1580",
         email: "adelaide@holyoake.com"},
        {name: "Perth",
         phone: "08 8354 1580",
         email: "perth@holyoake.com"},
        ]}
const clauses = {
    nz: {louver: "H1/VM3 CLAUSE 4.2.4.4. COMPLIANCE:",
         diffuser: "H1/VM3 CLAUSE 4.2.4.6. COMPLIANCE:",
         damper: "H1/VM3 CLAUSE 4.2.4.6. COMPLIANCE:",
    },
    au: {louver: "Clause J6D5 (4)(d) of NCC 2022 Volume 1",
         diffuser: "Clause J6D5 (4) of NCC 2022 Volume 1",
         damper: "Clause J6D5 (4) (h) of NCC 2022 Volume 1",
    }
}


function Footer({ country }){
    return (
        <View key="offices" style={{ flexDirection: 'row', justifyContent: 'space-around', paddingBottom: 25, borderBottom: '5px solid #0061c6' }} >
    {  offices[country].map((office, index) => (
        <View key={index} style={{ flexDirection: 'column', fontSize: 9, color: '#4a4b4c' }} >
        <Text>{ office.name }</Text>
        <Text>{ office.phone }</Text>
        <Text>{ office.email }</Text>
    </View>
    )
    ) }
  </View>
      );
}

function Project({ title, value }){
    return (
          <View key={title} style={{ flexDirection: 'row', justifyContent: 'space-around', flexGrow: 0 }}>
              <View key="title" style={{ ...styles.project, flexDirection: 'column', width: "50%", fontWeight: 700, textAlign: "left", paddingLeft: "0px" }} >
                <Text>{ title }</Text>
              </View>
              <View key="value" style={{ fontSize: 11, flexDirection: 'column', width: "50%", fontWeight: 100 }} >
                  <Text>{ value }</Text>
              </View>
          </View>
    )};

function Performance({ title, value, units, center, padding }){
    if ( ['m2', 'm^2'].includes(units) ){
        units = (
            <> <Text>m</Text>
            <Text style={{ fontSize: 6, verticalAlign: 'super' }}>2</Text>
            </>)
    }
    
    let align = 'left';
    let pad = padding || '0px';


    if ( ((center || ['Pax'])).includes(units) ){
        pad = '20px';
    }
    return (
          <View styles={styles.performance} key={title} style={{ flexDirection: 'row', justifyContent: 'space-around', flexGrow: 0 }}>
              <View key="title" style={{ flexDirection: 'column', width: "50%", fontWeight: 700, textAlign: align, paddingLeft: pad }} >
                <Text>{ title }</Text>
              </View>
              <View key="value" style={{ flexDirection: 'column', width: "50%", fontWeight: 100 }} >
                  <Text>{ value } { units }</Text>
              </View>
          </View>
    )};


function Acoustic({ calculator }){
    let columns = ['125Hz', '250Hz', '500Hz', '1kHz', '2kHz', '4kHz', '8kHz'];
    if ( calculator?.acoustic?.items.length > 0 ){
        return (
                    <>
                    <Text style={ styles?.h2 }>Acoustic Performance</Text>
                    <View styles={ styles.acoustic } style={{ flexDirection: 'row', justifyContent: 'space-around', flexGrow: 0 }}>
                            <View key="frequency" style={{ flexDirection: 'column', width: "50%" }} >
                                <Text>Frequency</Text>
                                </View>
                                      { calculator?.acoustic?.items.map((item) => (
                                          <View key={item["identifier"]} style={{ flexDirection: 'column', width: "50%", textAlign: 'center', paddingRight: 2, paddingLeft: 2 }} >
                                 <Text>{ item["identifier"] } (dB)</Text>
                               </View>
                                      ))}
                            </View>
                            <Text style={{ paddingTop: 10 }} ></Text>
                            {columns.map((column) => (
                                <View styles={ styles.acoustic } key={ column } style={{ flexDirection: 'row', justifyContent: 'space-around', flexGrow: 0 }}>
                                    <View key="{ column }" style={{ flexDirection: 'column', width: "50%", textAlign: 'right', paddingRight: 45 }} >
                                    <Text key={column}>{ column }</Text> 
                                        </View>
                                      { calculator?.acoustic?.items.map((item) => (
                                          <View key={ item["@id"] } style={{ flexDirection: 'column', width: "50%", textAlign: 'right', paddingRight: 35 }} >
                                            <Text>{ item[column].toLocaleString(undefined, {minimumFractionDigits:1}) }</Text> 
                                        </View>
                                      ))}
                            </View>
                                 ))}
                    </>
      )
    } else { return null;
 }
}

function ComplianceDamper( {calculator, item, table, variant, rating, application} ){
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("ComplianceDamper", calculator, item, table, variant, rating, application);
    }
    if ( table?.application_order?.includes(application.value) && rating.value ){
        return ( <>
            {table?.ratings?.[application.value].filter(
                    (item) => item.value == rating.value
                    ).map( (item) => (
                        <>
                            <Text style={ styles.h2 }>
                                {application.value} Fire Construction {rating.value} minutes</Text>
                            <Text>{item.construction}</Text>
                        </>))}
            {(table?.aperture_order?.includes(application.value)
                   && rating?.value) && (
                    table?.aperture?.[application.value].filter( (item) => (item.rating == rating.value && (variant?.distance || 0) >= item.min && (variant?.distance || 0) <= item.max && (item?.load_bearing === 'both' || item?.load_bearing === variant?.load_bearing))).map( (item) => (
                    <>
                    { (application.value.includes('Plasterboard Wall') && (variant?.distance || variant?.load_bearing)) && (
                        <Text>{ variant?.load_bearing ? 'Load Bearing': 'Non-Load Bearing'} Aperture: {variant.distance|| 0}mm requires:</Text>)
                    }
                    { (application.value === 'Timber Framed Floor') && (
                        <Text>Aperture: {variant.distance|| 0}mm requires:</Text>)
                    }
                    <Text>{item.construction}</Text>
                    </>
                    )))}
               </>
            )      
    } else {
        return null;
    }
}

function Compliance( {calculator, item, table} ){
    let clause =  clauses[getTld()][item['@type']];
    let pressure = calculator?.calculator?.pressure_drop?.intake
    let pressure_max = calculator?.calculator?.pressure_drop_max?.intake
    if (item['@type'] === 'diffuser'){
        pressure = Math.max.apply(0, table?.performance.filter(item => (['static_pressure', 'total_pressure'].includes(item['field_name']))).map(item => (item.value)));
        pressure_max = 30;
        const searchParams = new URLSearchParams(document.location.search)
        const query = searchParams.get('query');
        if (['DG-52', 'DG-17'].includes(table?.title || item?.title)){
            pressure_max = 12;
        } else if (query && JSON.parse(query).filter(item => (item['i'] === 'Subject_Diffuser_Application' && item['v'].includes('Supply'))).length){
            pressure_max = 40;
        } else if (query && JSON.parse(query).filter(item => (item['i'] === 'Subject_Diffuser_Application' && item['v'].includes('Return/Exhaust'))).length){
            pressure_max = 30;
        } else if (item?.Subject?.includes('Supply')){ 
            pressure_max = 40;
        }
    } else if (item['@type'] === 'damper'){
        pressure = table?.pressure_drop;
        pressure_max = table?.max_allowable_pressure;
        if ( table?.free_area_velocity > table?.max_recommended_velocity && pressure < pressure_max){
            pressure = pressure_max + 1;
        }
    }

    if ( pressure === undefined ){
        return (
            <>
             <Text style={ styles.h2 }>{ clause }</Text>
             <Text> { 'N/A due to unspecified performance' }</Text>
            </>
        )

    }

    return (

            <>
             <Text style={ styles.h2 }>{ clause }</Text>
                {  ( pressure > pressure_max ) ? (
                    <Text> { 'Non-compliant, ' + (item['@type'] === 'damper' ? '' : 'intake ') + 'pressure exceeds limit (' + pressure_max + ' Pa)' }</Text> ) : (
                        <Text> { 'Compliant, ' + (item['@type'] === 'damper' ? '' : 'intake ') + 'pressure within limit (' + pressure_max + ' Pa)' }</Text> 
                )}
            </>)
}


function DiffuserPerformance({ performance, calculator, table }){

    return (
        <>
            { table?.flow_option && (
                <Performance key="options" title="Options" />
            )}
            { table?.flow_options && Object.entries(table?.flow_option).map(([key, option]) => (
             <Performance key={key} title={ option } padding="20px"  />
            )) }
            { ( table?.performance) && (table?.performance.filter((item) => (item?.compliance_only !== true)).map((rec, index) => (
             <Performance key={index} title={ rec?.title } value={ rec?.value } units={ rec?.units || ''} center={['mx']} />

                    )))}
    </>
    )
}
function LouverPerformance({ performance, calculator }){
    let vol = '-';
    if (performance.volume){
        vol = performance.volume + ' l/s';
    }
    return (
        <>
         <Performance title="Nominal Size (w x h):" value={ calculator?.calculator?.nominal_size } />
            { (calculator?.calculator?.overall_depth) && (
            <Performance title="Overall Depth:" value={ calculator?.calculator?.overall_depth } units = 'mm'/> )}
            <Performance title="Airflow:" value={ vol } />
            { calculator?.calculator?.sections  && (
                <Performance title={ 'Sections (' + calculator?.calculator?.width_sections + ' x ' + calculator?.calculator?.height_sections + '):' }
                   value={ calculator?.calculator.section_width + ' mm x ' + calculator?.calculator.section_height + ' mm' } />
            )}
            { performance.volume > 0.0 && (
                            <>
                                <Performance title="Free Area Velocity:" value={ calculator?.calculator?.louver_velocity || '-' } units="m/s" /> 
                                <Performance title="Pressure Drop:" value="" units="" /> 
                                <Performance title="intake" value={ calculator?.calculator?.pressure_drop?.intake || '-' } units="Pa" /> 
                                <Performance title="exhaust" value={ calculator?.calculator?.pressure_drop?.exhaust || '-' } units="Pa" /> 
                            </>)
                        }
                        { performance?.width && performance?.height && (
                            <>
                             <Performance title="Free Area:" value={ calculator?.calculator?.pressure_area || '-' } units="m2" /> 
                             <Performance title="Free Area:" value={ calculator?.calculator?.free_area || '-' } units="%" />
                             <Performance title="Estimated Weight:" value={ calculator?.calculator?.unit_weight } units="kg" /> 
                          </>
                       )}
                  
          <Performance title="Efficiency:" value={ calculator?.calculator?.efficiency } />
          <Performance title="Wind Loading:" value={ calculator?.calculator?.wind_loading } />
            { calculator?.calculator?.sections  && (
                <>
                    <Text style={{ fontSize: 8, paddingTop: 20 }}>
                        Performance data is for one section only</Text></>
            )}
    </>
    )
}

export function ProductPDF({ item, calculator, performance, table, selection, variant, rating, application }) {
  const date = new Date().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
  const orientations = new Set(['Horizontal', 'Vertical']);
  const Subject = item?.Subject || [];
  const features = Subject.filter(i => (! orientations.has(i)));
  const searchParams = new URLSearchParams(document.location.search)
  if (searchParams.get('debug')) {
      console.log("Product PDF", item, calculator, performance, table, selection, features);
  }
  const title = table?.title || item?.title;

  return (
    <Document title={title} author="HolyoakeByPrice">
      <Page size="A4" style={styles.page}>
          <View key="header" style={styles.section}>
            <Image src={holyoakeLogo} style={styles.logo} />
            <Text key="title" style={ styles.h1 }>{ title }</Text>
            <Text key="description" style={ styles.description } >{ item?.description }</Text>
          </View>    
          <View key="main" style={{ flexDirection: 'row', justifyContent: 'space-around', flexGrow: 1 }}>
              <Image src={blueBanner} style={ styles.banner } />
              <View style={ styles.leftside } >
            <Text style={ styles?.h2Left }>Performance Table
            </Text>
            { item['@type'] === 'louver' && (
            <LouverPerformance performance={performance} calculator={calculator} />
            )}
            { item['@type'] === 'damper' && (
            <DiffuserPerformance performance={performance} calculator={calculator} table={table} />
            )}
            { item['@type'] === 'diffuser' && (
            <DiffuserPerformance performance={performance} calculator={calculator} table={table} />
            )}
         </View>
         <View key="right" style={{ flexDirection: 'column', whiteSpace: 'nowrap', whiteSpace: 'wrap', width: "50%", fontSize: 10, fontWeight: 100, color: '#4a4b4c', lineHeight: 1.2, marginLeft: 30, paddingLeft: 20, paddingRight: 8, paddingTop: 5 }} >
             <Project title="Project name:" value={selection?.project?.project_name|| ''} />
             <Project title="Reference:" value={selection?.project?.reference || ''} />
             <Text style={{ paddingBottom: '15px' }}></Text>
              { table?.performance.filter(i => i.limitation).map((rec, index) => (
            <Text key={index} style={ styles.h2Warn }>{ rec?.limitation } </Text>))} 
            <Compliance
                calculator={ calculator }
                item={item}
                table={table}
            />
            { item['@type'] === 'damper' && (
            <ComplianceDamper
                calculator={ calculator }
                item={item}
                table={table}
                variant={variant}
                rating={rating}
                application={application}
            />)}
            <Text style={ styles.h2 }>Overview</Text>
            <Text>{calculator?.overview}</Text>
             { (features.length > 0 || table?.attribute) && (
            <Text key="features" style={ styles?.h2 }>xFeatures</Text>)}
               { Subject.filter(i => orientations.has(i)).map((orientation, index) => (
                  <Text key={ index } style={ styles.list_element }> •    { orientation }</Text>
                  ))}
                { features.map((feature, index) => (
                   <Text key={ index } style={ styles.list_element }> •    { feature }</Text>
                  ))}
                { table?.attribute?.map((feature, index) => (
                   <Text key={ index } style={ styles.list_element }> •    { feature }</Text>
                  ))}
            <Text style={{ paddingTop: 15 }} ></Text>
            <Acoustic calculator={ calculator?.calculator } />
        </View>
      </View>
          <Image src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/huge`,)} style={{position: 'absolute', bottom: 100, left: 20, width: '50%' }} />
        <Footer country={ getTld() } />
        <Text style={ styles.footdate }>Report Generated on the {date}</Text>
      </Page>
    { (item['@type'] === 'damper' && Object.keys(table.application_diagrams).includes(application?.value))  && (
         table.application_diagrams[application.value].map(diagram => (
             <Page size="A4" orientation={diagram.includes('p.png') ? 'portrait' : 'landscape'}>
            <Image src={ flattenToAppURL(`${item['@id']}/${diagram}/@@images/image/huge`,)} />
        </Page>)))
    }
    </Document>
  );
};


export function DownloadPdfButton ({ item, calculator, performance, table, selection, disabled, setDisabled, variant, rating, application, onClose }) {
    const [load, setLoad] = useState(false);

    let PDF = ProductPDF;
    if (item['@type'] === 'diffuser'){
        PDF = ProductPDF;
    } else {
        PDF = ProductPDF;
    }
    const mydoc = (<PDF item={item}
                        calculator={calculator}
                        performance={performance}
                        table={table}
                        selection={selection}
                        variant={variant}
                        rating={rating}
                        application={application}
                    />); 

    function download(){
        trackEvent({category: 'pdf', action: "download", name: (table?.title || item?.title)});
        onClose()
    }

    const filename = [selection?.project?.project_name,
                      selection?.project?.reference,
                      item['@type'],
                      table?.title || item['@id'].split('/').at(-1)].filter(item => (item)).reduce((prev, curr) => prev + '-' + curr).replace(/ /g, '-') + ".pdf";

    return (
     <PDFDownloadLink className={"ui button add " + (load ? 'yellow' : 'green')} 
              document={ mydoc }
              fileName={ filename }
              onClick={download}
              style={{pointerEvents: ((disabled || load) ? 'none' : 'auto')}}
              >
         {({ blob, url, loading, error }) => (
             (useEffect(() => {
                  if (loading){
                      trackEvent({category: 'pdf', action: "generate", name: (table?.title || item?.title)});
                  }
                  setLoad(loading);
                  setDisabled(loading);
             }, [loading]) || loading) ? 'Generating PDF...' : 'Download PDF')
      }
    </PDFDownloadLink>
    )
}

function DownloadPdfForm({ item, calculator, performance, table, selection, setSelection, variant, rating, application, onClose }) {
    const [disabled, setDisabled] = useState(true);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        const project = {...selection.project};
        project[name] = value;
        if ( isEqual(project, selection.project)){
            setDisabled(false);
        } else {
            setDisabled(true);
            setSelection({...selection,
                          project: {...project}});
        }
    };
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("DownloadPdfForm", item, calculator, performance, table, selection, variant, rating, application);
    }

    return (
        <div className="ui page modals dimmer transition visible active pdfform">
            <div className="ui modal transition visible active modal pdf">
                <div className="actions">
                    <Button className="ui basic circular floated button orange" aria-label="Close" title="close" onClick={onClose}>Close</Button>
                </div>
                <div className="ui header">
                    <h1 className="center">{table?.title || item.title}</h1>
                </div>
                <form className="project">
                    <Table className="pdfform">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className="blue text">Project Name:</Table.Cell>
                                <Table.Cell> <DebounceInput debounceTimeout={500} type="text" placeholder="Optional"  name="project_name" value={selection?.project?.project_name} onChange={(event)=>handleFieldChange(event)} className="project" /></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="blue text">Reference:</Table.Cell>
                                <Table.Cell>
            <DebounceInput debounceTimeout={500} type="text" placeholder="Optional" name="reference" value={selection?.project?.reference|| ''} onChange={(event)=>handleFieldChange(event)} className="reference" /></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell /> 
                                <Table.Cell> 
                <DownloadPdfButton
                    item={item}
                    calculator={calculator}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    performance={performance}
                    table={table}
                    selection={selection} 
                    onClose={onClose}
                    setSelection={setSelection}
                    variant={variant}
                    rating={rating}
                    application={application}
                />
                                </Table.Cell> 
                           </Table.Row>
                    </Table.Body>
                </Table>
                </form>
            </div>
        </div>
       )
}


export function GeneratePdfForm ({ item, calculator, performance, table, selection, setSelection, variant, rating, application }) {
  const [showModal, setShowModal] = useState(false)
  const [documentGenerated, setdocumentGenerated] = useState(false);
  return (
        <>
            <Button className="ui button add blue"
                onClick={() => setShowModal(true) }>Create PDF</Button>
            {showModal && createPortal(

                <DownloadPdfForm item={item} calculator={calculator}
                               performance={performance}
                               table={table}
                               selection={selection}
                               setSelection={setSelection}
                               variant={variant}
                               rating={rating}
                               application={application}
                    onClose={() => setShowModal(false)} />,

                document.body
            )}
        </>
    )
}

export function GeneratePdfButton ({ item, calculator, performance, table, selection, variant, rating, application }) {
  const [documentGenerated, setdocumentGenerated] = useState(false);
  return ( !documentGenerated ? (
            <button
              className="ui button add blue" 
                onClick={() => setdocumentGenerated(true)}
            >
              Create PDF
            </button>
          ) : (
            <DownloadPdfButton
                item={item}
                calculator={calculator}
                performance={performance}
                table={table}
                selection={selection}
                variant={variant}
                rating={rating}
                application={application}
            />
          ) )
}

