import { Header, Table } from 'semantic-ui-react';

export function Acoustic({ calculator }){
    let columns = ['125Hz', '250Hz', '500Hz', '1kHz', '2kHz', '4kHz', '8kHz'];
    if ( calculator?.acoustic?.items.length > 0 ){
        return (
                    <>
                    <Header sub>Acoustic Performance</Header>
                    <Table className="acoustic">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Frequency</Table.HeaderCell>
                                      { calculator?.acoustic?.items.map((item) => (
                                    <Table.HeaderCell key={item["identifier"]}>{ item['identifier'] } (dB)</Table.HeaderCell> 
                                      ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {columns.map((column) => (
                                  <Table.Row key={column}>
                                    <Table.Cell key={column}>{ column }</Table.Cell> 
                                      { calculator?.acoustic?.items.map((item) => (
                                    <Table.Cell key={item["@id"]}>{ item[column].toLocaleString(undefined, {minimumFractionDigits:1}) }</Table.Cell> 
                                      ))}
                                  </Table.Row>
                                 ))}
                        </Table.Body>
                    </Table>
                    </>
      )
    } else { return null;
 }
}
