
import { GET_DIFFUSER } from '../../constants/ActionTypes';

export function getDiffuser(url, specification) {
  return {
    type: GET_DIFFUSER,
    request: {
      op: 'post',
      path: `${url}/@diffuser`,
      data: specification,
    },
  };
}
