import { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { createPortal } from 'react-dom';
import { Popup } from '@plone/volto/components';
import { trackEvent } from '@eeacms/volto-matomo/utils';

export function ListMulti({ onClose, item, calculator, table, variant }) {
    trackEvent({category: 'performance', action: "comparison", name: item?.title});
    return (
        <div className="ui page modals dimmer transition visible active">
            <div className="ui modal transition visible active modal">
                <div className="actions">
                    <Button className="ui basic circular floated button orange" aria-label="Close" title="close" onClick={onClose}>Close</Button>
                </div>
                <div className="ui header">
                    <h1 className="center">{item.title}</h1>
                </div>
                <div className="content popup">
                  <VariantMulti calculator={calculator} variant={variant}/>
                </div>
            </div>
        </div>
    );
}

export function ShowMulti({item, calculator, table, variant}) {
    const [showModal, setShowModal] = useState(false);
    if ( !calculator?.variants?.dynamic && calculator?.variants?.table?.length > 1) {
    return (
        <>
            <Button className="ui button add blue" onClick={() => setShowModal(true)}>Compare</Button>
            {showModal && createPortal(

                <ListMulti item={item} calculator={calculator} table={table} variant={variant} onClose={() => setShowModal(false)} />,
                document.body
            )}
        </>
    )
    } else {
        return null;
    }
}

export function VariantMulti( { calculator, variant} ){
    if ( calculator?.variants === undefined ){
        return null;
    }
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("VariantMulti", calculator, variant);
    }
    const attributes = [...calculator?.option_order];
    attributes.splice('variant', 1);
    const variants = calculator?.variants?.table.filter(item => (
                       attributes.filter(option => (variant?.[option] == item[option])).length == attributes.length ));

    let unique = new Set([])
    let headers = new Set([])
    variants.map(item => (item.performance.map(field => ( 
         (field.value || field.header) && unique.add(field.title)
            )))); 

    variants.map(item => (item.performance.map(field => ( 
         (field.header) && headers.add(field.title)
            )))); 
    const indent = new Set(['Horizontal', 'Vertical']);

    const rows = calculator?.variants?.fields.filter(row => ( unique.has(row)));

    return (
        <>
                    <Table className="listing">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                      { variants.map((header) => (
                                    <Table.HeaderCell key={header["@id"]}>{ header['title'] }
                                       { attributes.map((attribute) => (<><br/>{variant[attribute]}</>))}
                                                                           </Table.HeaderCell> 
                                      ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows.map((row, index) => (
                                  <Table.Row key={index}>
                                      { headers.has(row) ? (
                                          <Table.Cell key={row} colSpan={ variants.length + 1 }>{ row }</Table.Cell> ):(
                                              <>
                                                  <Table.Cell className={ indent.has(row) ? 'heading indent' : 'heading'} key={row}>{ row }</Table.Cell> 
                                      { variants.map((header) => (
                                          <Table.Cell key={header["@id"]}>{ header.performance.filter(perf => (perf['title'] == row)).map((rec) => (  (rec['value'] || '') + ' ' + (rec['units'] || '')  )) }</Table.Cell> 
                                      ))} </>)}
                                  </Table.Row>
                                 ))}
                        </Table.Body>
                    </Table>
                    </>)
}

export function VariantTableNew( { item } ){
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("VariantTableNew", item)
    }
    if ( item === undefined ){
        return null;
    }
    return (
        <>
            { ( item?.performance) && (item?.performance.map((rec, index) => (
                rec?.header ? (
                    <tr key={ index }><td colSpan="2" className="heading">{ rec?.title }</td></tr> ) : (
             <tr key={ index }><td className={['Horizontal', 'Vertical'].includes(rec?.title) ? 'heading indent' : 'heading'}>{ rec?.title }:</td>
                 {rec?.limitation ? (
                <td className="result">
                  <strong className="warn">{ rec?.value + ' ' + (rec?.units || '') }</strong>
                  <Popup content={ rec?.limitation } trigger={<Button className="ui button warn">Limitation</Button>} /> 
                </td>
                 ) : (
                 <td className="result"> { (rec?.value || '') + ' ' + (rec?.units || '') }</td>)}
             </tr>)
            )))}
                    </>)
}

function Units ( { unit } ){
    if ( unit === undefined ){
        return null;
    }
    if (unit === 'm2' || unit === 'm^2'){
        return (<>m<sup>2</sup></>);
    }
    return ' ' + unit;
}

export function VariantTable( { item } ){
    if ( item === undefined ){
        return null;
    }
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("VariantTable", item)
    }
    return (
        <>
            { ( item?.performance) && (item?.performance.filter((message) => (message?.compliance_only !== true)).map((rec, index) => (
                rec?.header ? (
                    <tr key={ index }><td colSpan="2" className="heading">{ rec?.title }</td></tr> ) : (
                        <tr key={ index }><td className={['Horizontal', 'Vertical'].includes(rec?.title) ? 'heading indent' : 'heading' + (rec?.class_name ? ' ' + rec?.class_name : '')}>{ rec?.title }:</td>
                 {rec?.limitation ? (
                <td className={'result' + (rec?.class_name ? ' ' + rec?.class_name : '')}>
                    <strong className="warn">{ rec?.value }<Units unit={rec?.units} /></strong>
                  <Popup content={ rec?.limitation } trigger={<Button className="ui button warn">Limitation</Button>} /> 
                </td>
                 ) : (
                     <td className={'result' + (rec?.class_name ? ' ' + rec?.class_name : '')}>{ (rec?.value || '') }<Units unit={rec?.units} /></td>)}
             </tr>)
            )))}
                    </>)
}
