/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import { getParentSiteUrl } from '../../../../siteurl';

const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  const default_year = 2023;
  const current_year = new Date().getFullYear();

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color="orange"
      textAlign="left"
      id="footer"
    >
      <Container>
        <Segment basic inverted color="orange" className="center footer">
          <div className="copyright center nowrap">
            <span className="copyrightLink">
              <a href={getParentSiteUrl()}>
                <FormattedMessage 
                  id="Copyright {copyright_year} © Holyoake Air Management Solutions"
                  defaultMessage="Copyright {copyright_year} © Holyoake Air Management Solutions"
                  values={{
                    copyright_year: (current_year === default_year) ? current_year : default_year + '-' + current_year,
                  }}
                />
              </a>
            </span>
            <span className="footerLinks center nowrap">
                <span className="Privacy"> <a href={getParentSiteUrl() + '/privacy-policy'}>Privacy Policy</a></span>{ ' | ' }
                <span className="Terms"> <a href={getParentSiteUrl() + '/terms-and-conditions'}>Terms & Conditions</a></span>{ ' | ' }
                <span className="Conditions"> <a href={getParentSiteUrl() + '/conditions-of-sale'}>Conditions of Sales</a></span>{ ' | ' }
                <span className="Contactus"> <a href={getParentSiteUrl() + '/contact-us'}>Contact Us</a></span>
            </span>
          </div>
        </Segment>
        <Segment basic className="ui discreet segment hosting center">
          <a className="item" href="https://www.thevirtual.co.nz">Built by theVirtual Limited</a>
        </Segment>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
