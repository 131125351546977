/**
 * Add your config changes here.
 */
import ProductView from './customizations/components/Views/ProductView';
import MixedFieldDynamicWidget from './customizations/components/manage/Widgets/mixedfield_dynamic_widget';
import LouverTemplate from './customizations/components/manage/Blocks/Listing/LouverTemplate';
import DiffuserTemplate from './customizations/components/manage/Blocks/Listing/DiffuserTemplate';
import DiffuserVariantTemplate from './customizations/components/manage/Blocks/Listing/DiffuserVariantTemplate';
import DamperTemplate from './customizations/components/manage/Blocks/Listing/DamperTemplate';
import DamperVariantTemplate from './customizations/components/manage/Blocks/Listing/DamperVariantTemplate';

// All your imports, required for the config, to be added BEFORE this line
import '@plone/volto/config';


export default function applyConfig(config) {
    config.views = {
        ...config.views,
        contentTypesViews: {
            ...config.views.contentTypesViews,
            product_view: ProductView,
        },
        layoutViews: {
            ...config.views.layoutViews,
            product_view: ProductView,
        },
        layoutViewsNamesMapping: {
            ...config.views.layoutViewsNamesMapping,
            product_view: ProductView,
        },
    }
    config.blocks.blocksConfig.listing.variations.push({
        id: 'louver',
        title: 'Louver',
        template: LouverTemplate,
    })
    config.blocks.blocksConfig.listing.variations.push({
        id: 'diffuser',
        title: 'Diffuser',
        template: DiffuserTemplate,
    })
    config.blocks.blocksConfig.listing.variations.push({
        id: 'diffuser_variant',
        title: 'Diffuser Variant',
        template: DiffuserVariantTemplate,
    })
    config.blocks.blocksConfig.listing.variations.push({
        id: 'damper',
        title: 'Damper',
        template: DamperTemplate,
    })
    config.blocks.blocksConfig.listing.variations.push({
        id: 'damper_variant',
        title: 'Damper Variant',
        template: DiffuserVariantTemplate,
    })
    config.widgets.widget.mixedfield_widget = MixedFieldDynamicWidget;
    return config;
}
