/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';
import eeacmsvoltoMatomo from '@eeacms/volto-matomo';

const addonsInfo = [{"modulePath":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/@plone/volto/packages/volto-slate/package.json","version":"17.6.1","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"volto-subblocks","version":"2.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/volto-subblocks/src","packageJson":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/volto-subblocks/package.json","addons":[]},{"name":"volto-form-block","version":"3.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/volto-form-block/src","packageJson":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/volto-form-block/package.json","addons":["volto-subblocks"]},{"name":"@eeacms/volto-matomo","version":"4.2.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/@eeacms/volto-matomo/src","packageJson":"/var/lib/zope6.0.phau/volto/holyoake.volto/2/node_modules/@eeacms/volto-matomo/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoSubblocks, voltoFormBlock, eeacmsvoltoMatomo];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
