import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import qs from 'query-string';
import { isEqual } from 'lodash';
import { trackEvent } from '@eeacms/volto-matomo/utils';

const messages = defineMessages({
  searchResults: {
    id: 'Search results',
    defaultMessage: 'Search results',
  },
});

const SearchDetails = ({ total, text, as = 'h4', data }) => {
  const El = as;
  const intl = useIntl(); 
  const [last, setLast] = useState()

  const location = useLocation();

  useEffect(() => {
    if (data.showTotalResults && typeof document !== 'undefined' && total !== undefined){
        const query = data?.query?.query || [];
        const portal_type = query.filter((item) => item.i === 'portal_type').map(item => item.v[0]); 
        const search = JSON.parse(qs.parse(document.location.search)?.query || '[]' );
        const querys = search.filter((item) => !['portal_type', 'review_state'].includes(item.i)).map((item) => item.i.replace('Subject_Diffuser_', '').replace('Subject_Louver_', '') + '=' + (typeof item.v === 'string' ? item.v : item.v.join('|'))); 
        if (!isEqual(querys, last) && (querys.length > 0 || total > 0 )){
            if (location.pathname.includes(portal_type[0])){
                trackEvent({category: 'selection',
                            action: portal_type[0],
                            name: querys.join(', '),
                            value: total});
                if (qs.parse(document.location.search)?.debug){
                    console.log("TrackEvent", {category: 'selection',
                                               action: portal_type[0],
                                               name: querys.join(', '),
                                               value: total});
                }
            }
        }
            setLast(querys);
    }
  }, [data])

  return (
    <El className="search-details">
      <>
        {text && (
          <>
            <FormattedMessage
              id="Searched for: <em>{searchedtext}</em>."
              defaultMessage="Searched for: <em>{searchedtext}</em>."
              values={{
                em: (...chunks) => <em>{chunks}</em>,
                searchedtext: text,
              }}
            />
          </>
        )}
        {data.showTotalResults && (
          <>
            {' '}
            {intl.formatMessage(messages.searchResults)}: {total}
          </>
        )}
      </>
    </El>
  );
};

export default SearchDetails;
