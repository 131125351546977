import React from 'react';
import { Table } from 'semantic-ui-react';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const MixedFieldDynamicWidget = (props) => {
    return (
        <ObjectListWidget
            schema={props.widgetOptions.frontendOptions.schema}
            {...props}
            value={props.value?.items || props.default?.items || []}
            onChange={(id, value) => props.onChange(id, { items: value })}
        />
    );
};


function FormatField( {field, data} ){
    console.log(field, data);
    if ( data?.constructor === Array ) {
        return data.join(', ') || '';
    } else {
        if ( field?.choices && data ){
            let options = field.choices.filter(value => (value[0] == data));
            if (options?.length > 0) {return options[0][1];}
        }
        return data || null;
    }
}


export const MixedFieldDynamicViewWidget = (schema) => {
    let values = schema?.content || schema?.values;
    let fields = schema?.schema?.properties;
    return (
                    <Table className="mixed_field">
                        <Table.Header>
                            <Table.Row key={ schema?.title } >
                                { schema.schema.fieldsets[0]?.fields.map(( field, index ) => (
                                <Table.HeaderCell key={ index }>{ schema.schema.properties[field].title }</Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                                {values?.items.map((item) => (
                                <>
                                  <Table.Row key={item['@id']}>
                                { schema.schema.fieldsets[0]?.fields.map(( field, index ) => (
                                    <Table.Cell key={ index }>
                                        <FormatField field={fields[field]} data={item[field]} />
                                    </Table.Cell> 
                                ))}
                                  </Table.Row>
                                  </>
                                      ))}
                        </Table.Body>
                    </Table>
    )

};

export default MixedFieldDynamicWidget;
