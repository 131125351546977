/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const GET_CALCULATOR = 'GET_CALCULATOR';
export const GET_DIFFUSER = 'GET_DIFFUSER';
export const GET_DAMPER = 'GET_DAMPER';
