import { GET_DIFFUSER } from '../../constants/ActionTypes';
import { cloneDeep, map } from 'lodash';

const initialState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
};

export default function diffuser(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_DIFFUSER}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_DIFFUSER}_SUCCESS`:
      return {
        ...state,
        result: cloneDeep(action.result?.diffuser),
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_DIFFUSER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
