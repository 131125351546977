import { GET_DAMPER } from '../../constants/ActionTypes';
import { cloneDeep, map } from 'lodash';

const initialState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
};

export default function damper(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_DAMPER}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_DAMPER}_SUCCESS`:
      return {
        ...state,
        result: cloneDeep(action.result?.damper),
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_DAMPER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
