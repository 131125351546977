import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createPortal } from 'react-dom';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConditionalLink, Component, Popup, Modal, UniversalLink, Icon } from '@plone/volto/components';
import { getBaseUrl, flattenToAppURL, getContentIcon } from '@plone/volto/helpers';

import { Container, Image, Button, Header, List, ListItem, Table } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { getContent } from '@plone/volto/actions';
import { getCalculator } from '../../../../../actions';
import { useSessionStorage } from '../../../../../helpers';
import { SelectionTable, SelectionStorage } from '../../../../../selection/table';
import { GeneratePdfButton } from '../../../../../selection/document';
import { Acoustic } from '../../../../../selection/acoustic';
import { ShowMore, ListContent } from '../../../../../selection/listcontent';
import { RelatedItems, ContentLink } from '../../../../../selection/relateditems';
//import { trackEvent } from '@eeacms/volto-matomo/utils';
import moment from 'moment';

class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputSubmit = this.handleInputSubmit.bind(this);
    }

    handleInputChange(event) {    
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.setPerformance(
            {
             ...this.props.performance,
             [name]: value
            }
        );
    }

    handleInputSubmit(event) {
        const options = {category: 'calculate', action: 'width=' + this.props.performance.width + ' height=' + this.props.performance.height + ' volume=' + this.props.performance.volume };
        // trackEvent(options); 
        event.preventDefault();
    }

    render() {
        return (
            <form className="calculator" onSubmit={this.handleInputSubmit}>       
                <label>Width: <input type="number" name="width" value={this.props.performance.width || ''} onChange={this.handleInputChange} />mm</label>
                <label>Height: <input type="number" name="height" value={this.props.performance.height || ''} onChange={this.handleInputChange} />mm</label>
                <label>Duct Diameter: <input type="number" name="diameter" value={this.props.performance.diameter || ''} onChange={this.handleInputChange} />mm</label>
                <label>Volume: <input type="number" name="volume" value={this.props.performance.volume || ''} onChange={this.handleInputChange} />l/s</label>
                <label>Mode: <Select type="text" name="mode" value={this.props.performance.mode || ''} onChange={this.handleInputChange} /></label>
            </form>
        );
    }
}

function Intake({ calculator }){
    const intake = calculator?.pressure_drop?.intake;
    if ( intake > calculator?.pressure_drop_max?.intake ){
        const pressure = "pressure exceeds permissible levels (" + calculator?.pressure_drop_max?.intake + " Pa) as " + calculator?.limits?.pressure_limitation;
        return (
            <>
                    <strong className="warn">{ intake } Pa</strong>
                    <Popup content={ pressure } trigger={<Button className="ui button warn">Limitation</Button>} /> 
            </>
    )}
    return ( <> { intake || '-' } Pa </> )
}

function AreaVelocity( { calculator }){
    if ( calculator?.diffuser_velocity > calculator?.limits?.velocity_max ){
        const velocity = "velocities above " + calculator?.limits?.velocity_max + "m/s could incur water carryover and increased noise";
        return (
            <>
                    <strong className="warn">{ calculator?.diffuser_velocity || '-' } m/s</strong>
                    <Popup content={ velocity } trigger={<Button className="ui button warn">Limitation</Button>} /> 
            </>
        )
    }
    return (
        <> { calculator?.diffuser_velocity || '-' } m/s </>
    )
}

function ShowCalculation({ performance, calculator, item_id }) {

    const checked = calculator?.checked;
    const [variant, setVariant] = useState(checked);

    let perf = '-';
    let vol = '-';
    let table_dimension = 'listing performance';
    if (performance.width && performance.height){
        perf = performance.width + ' mm x ' + performance.height + ' mm';
        if (performance.volume){
            table_dimension = 'listing performance volume';
        } else {
            table_dimension = 'listing performance size';
        }

    }
    if (performance.volume){
        vol = performance.volume + ' l/s';
    }
    return (
            <>
                {(calculator?.option_order) && (calculator?.option_order.map((option) => (
                    <> 
                    <Select options={ calculator?.options[option] } defaultValue={ calculator?.options[option].filter(item => (item?.checked))[0] } onChange={ (item) => setVariant({...variant,
                                                                                                                                                                                    option:item}) } />
                    </>
                )))}
                <h4 className="performance table">Performance Table</h4>
                <div className="top-list listing-performance">
                    <table className="listing performance">
                        <tbody>
                            <tr><td className="heading">Nominal Size:</td><td className="result">{ calculator?.calculator.errors }</td></tr>
                            <tr><td className="heading">Airflow:</td><td className="result">{ vol }</td></tr>
                            { ( variant?.value || checked?.value ) && (
                            <tr><td className="heading">Optimum:</td><td className="result">{ calculator?.variants?.table[variant?.value || checked?.value].performance.optimum } l/s</td></tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        )
}    

function AddSelection({selection, setSelection, calculator, model}) {
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')){
        console.log(selection, calculator);
    }
    return (
            <Button className="ui button add blue" title="Add selection" onClick={() => setSelection({items:[...selection?.items,
             {id: selection?.items.length + 1,
              amount: 1,
              reference: '',
              model: model,
              nominal_size: calculator?.calculator?.nominal_size,
              sections: calculator?.calculator?.sections,
              efficiency: calculator?.calculator?.efficiency,
              wind_loading: (calculator?.calculator?.wind_loading || '-')
             }]})}>+</Button>
    )
}

const DiffuserVariantTemplate = ({ items, linkTitle, linkHref, isEditMode, popup }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';
  
    if (isInternalURL(href)) {
        link = (
            <ConditionalLink className="ui raised segments modal" to={flattenToAppURL(href)} condition={!isEditMode}>
            {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a className="ui raised segments modal" href={href}>{linkTitle || href}</a>;
    }
    const [performance, setPerformance] = useSessionStorage('diffuser_performance', {width: undefined, height: undefined, diameter: undefined, volume: undefined, mode: undefined });
    const [selection, setSelection] = SelectionStorage();

    const dispatch = useDispatch();
    const calculator = useSelector((state) => state.calculator?.result);
    const uids = items.map(item => item.UID);
  
    const prefs = { ...performance,
                   type: "diffuser variant",
                   uids: uids}
    const location = useLocation();
    useEffect(() => {
        dispatch(
            getCalculator(location.pathname, prefs));
    }, [dispatch, performance]);
  
    return (
        <>
            <NameForm performance={ performance } setPerformance={ setPerformance } />
            <SelectionTable selection={ selection } setSelection={ setSelection } />
            <div className="items summaryplus modals active visible dimmable dimmed">
                {items.map((item) => (
                    <div className="ui listing-body left three item-list" key={item['@id']}>
                        <div className="top-list listing-image">
                            <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                        </div>
                        <div className="top-list listing-contents">
                            <h1>{item.title || item.id}</h1>
                            <h1 className="diffuserdesc">{item.description}</h1>
                        </div>
                        <ShowCalculation performance={ performance } calculator={ calculator?.[item.UID] } item_id={ item['@id'] } />
                        <Acoustic calculator={calculator?.[item.UID]?.calculator}/>
                        <RelatedItems related={ calculator?.[item.UID]?.related } />
                        <div className="top-list listing-button">
                        <AddSelection selection={ selection } setSelection={ setSelection } calculator={ calculator?.[item.UID] } model={ item?.title } />
                        <GeneratePdfButton item={ item } calculator={ calculator?.[item.UID] } performance={ performance } />
                        <ShowMore item={ item } calculator={ calculator?.[item.UID] } />
                        </div>
                    </div>
                ))}
            </div>
            {link && <div className="footer">{link}</div>}
        </>
    );
};

DiffuserVariantTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default DiffuserVariantTemplate;
