
import { GET_CALCULATOR } from '../../constants/ActionTypes';

export function getCalculator(url, specification) {
  return {
    type: GET_CALCULATOR,
    request: {
      op: 'post',
      path: `${url}/@calculator`,
      data: specification,
    },
  };
}
