import { useEffect, useState } from 'react';

function getParentSiteUrl(){
    const [parentUrl, setParentUrl] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined'){
            let url = window?.location;
            setParentUrl(url?.protocol + '//' + url?.host.replace('selection.', ''));
            }
    }, [parentUrl]);
        
    return parentUrl;
}
function getTld(){
    const [tld, setTld] = useState('au');

    useEffect(() => {
        if (typeof window !== 'undefined'){
            const domain_tlf = window.location.hostname.split('.').at(-1);
            setTld( (domain_tlf === 'localhost') ? 'au' : domain_tlf);
            }
    }, [tld]);
        
    return tld;
}
function getDeployment(){
    const [deployment, setDeployment] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined'){
            const domain = window.location.hostname.split('.');
            if (domain.includes('dev')) {
                setDeployment('DEVELOPMENT');
            } else if (domain.includes('acpt')) {
                setDeployment('ACCEPTANCE')
            } else if (domain.includes('localhost')) {
                setDeployment('DEVELOPMENT')
            }
        }
    }, [deployment]);
        
    return deployment;
}

export { getParentSiteUrl, getTld, getDeployment };
