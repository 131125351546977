/**
 * Root reducer.
 * @module reducers/root
 */
import defaultReducers from '@plone/volto/reducers';
import calculator from './calculator/calculator';
import damper from './damper/damper';
import diffuser from './diffuser/diffuser';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  calculator,
  damper,
  diffuser,
};

export default reducers;
