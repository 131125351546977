import { useState, useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { CSVLink } from "react-csv";
import { useSessionStorage } from '../helpers'
import { trackEvent } from '@eeacms/volto-matomo/utils';

export function SelectionStorage() {
    return useSessionStorage('selection', {items:{'damper': [],
                                                  'diffuser':[],
                                                  'louver':[]},
                                           project:{'project_name': '',
                                                    'reference': ''}});
}


export function SelectionTable({selection, setSelection}) {
    const headers = {'louver':[
        {'label': 'Reference', key: 'reference', input: true},
        {'label': 'Model', key: 'model'},
        {'label': 'Nominal Size', key: 'nominal_size'},
        {'label': 'Section Size', key: 'sections'},
        {'label': 'Flow Rate', key: 'flow_rate'},
        {'label': 'Free Area Velocity', key: 'free_area_velocity'},
        {'label': 'Pressure Drop (intake)', key: 'pressure_drop_intake'},
        {'label': 'Pressure Drop (exhaust)', key: 'pressure_drop_exhaust'},
        {'label': 'Free Area', key: 'free_area'},
        {'label': 'Estimated Weight', key: 'estimated_weight'},
        {'label': 'Efficiency', key: 'efficiency'},
        {'label': 'Wind Loading', key: 'wind_loading'},
        {'label': 'Amount', key: 'amount', input: true}],
        'damper': [
        {'label': 'Reference', key: 'reference', input: true},
        {'label': 'Model', key: 'model'},
        {'label': 'Nominal Size', key: 'nominal_size'},
        {'label': 'Section Size', key: 'sections'},
        {'label': 'Penetration Size', key: 'penetration_size'},
        {'label': 'Overall Size', key: 'overall_size'},
        {'label': 'Flow Rate', key: 'flow_rate'},
        {'label': 'Free Area Velocity', key: 'free_area_velocity'},
        {'label': 'Free Area', key: 'free_area'},
        {'label': 'Free Area (%)', key: 'free_area_pc'},
        {'label': 'Pressure Drop', key: 'pressure_drop'},
        {'label': 'Estimated Weight', key: 'estimated_weight'},
        {'label': 'Amount', key: 'amount', input: true}],
        'diffuser': [
        {'label': 'Reference', key: 'reference', input: true},
        {'label': 'Model', key: 'model'},
        {'label': 'Attributes', key: 'attributes'},
        {'label': 'Nominal Size', key: 'nominal_size'},
        {'label': 'Overall Size', key: 'overall_size'},
        {'label': 'Flow Rate', key: 'flow_rate'},
        {'label': 'Flow Rate Side A', key: 'flow_rate_side_a'},
        {'label': 'Flow Rate Side B', key: 'flow_rate_side_b'},
        {'label': 'Static Pressure', key: 'static_pressure'},
        {'label': 'Horizontal Throw @ 0.25m/s', key: 'horizontal_throw_0.25'},
        {'label': 'Vertical Throw @ 0.25m/s', key: 'vertical_throw_0.25'},
        {'label': 'Side A Throw @ 0.25m/s', key: 'side_a_throw_0.25'},
        {'label': 'Side B Throw @ 0.25m/s', key: 'side_b_throw_0.25'},
        {'label': 'Throw @ 0.25m/s', key: 'throw_0.25'},
        {'label': 'Spread @ 0.25m/s', key: 'spread_0.25'},
        {'label': 'NC', key: 'nc'},
        {'label': 'Amount', key: 'amount', input: true},
]
    };
    const [showTable, setShowTable] = useState(false);
    // Hide entirely if no entries
    /* if ( Object.values(selection.items).reduce((a, e)  => a + e.length, 0) == 0){
        return null;
    }*/
    useEffect(() => {
        if ( Object.values(selection.items).reduce((a, e)  => a + e.length, 0) == 1){
            setShowTable(true);
        }
    }, [selection]);

    const handleClear = (event, key) => {
        trackEvent({category: 'selection', action: "clear", name: key});
        setSelection({...selection,
                     items: {...selection.items,
                              [key]:[]}});
        if ( Object.values(selection.items).reduce((a, e)  => a + e.length, 0) == 0){
            setShowTable(false);
        }
    };
    const handleRowDel = (event, key, row) => {
        let list = [...selection.items[key]]
        trackEvent({category: 'selection', action: "delete", name: list[row]['model']});
        list.splice(row, 1);
        setSelection({...selection,
                      items: {...selection.items,
                              [key]:[...list]}});
        if ( Object.values(selection.items).reduce((a, e)  => a + e.length, 0) == 0){
            setShowTable(false);
        }
    };
    const handleChange = (event, key, row) => {
        const { name, value } = event.target;
        const list = [...selection.items[key]];
        list[row][name] = value;
        trackEvent({category: 'selection', action: "change", name: list[row]['model'], value: list[row]['amount']});
        setSelection({...selection,
                      items: {...selection.items,
                              [key]:[...list]}});
    };
    const handleProjectChange = (event) => {
        const { name, value } = event.target;
        const project = {...selection.project};
        project[name] = value;
        setSelection({...selection,
                      project: {...project}});
    };
    const searchParams = new URLSearchParams(document.location.search)
    const pathname = document.location.pathname;
    if (searchParams.get('debug')){
        console.log(selection);
    }


    function download ( {product, items} ){
        trackEvent({category: 'selection', action: "download", name: product, value: items.length });
    }

    function selectionButton ( value ){
        trackEvent({category: 'selection', action: (value ? "show" : "hide")});
        setShowTable(value); 
    }

    if ( showTable )  {
        return (
            <>
            <Button className="ui button add blue" onClick={() => selectionButton(false)}>Hide Selection</Button> 
            <form className="project">
                <h3 className="blue text">Project Name: <input type="text" name="project_name" value={selection?.project?.project_name} onChange={(event)=>handleProjectChange(event)} className="project" /></h3>
            </form>
                { Object.entries(selection.items).filter(([product, items]) => (items.length || pathname.includes(product))).map(([product, items]) => (
            <form className="selectionForm" key={ product }> 

                <h3 className="blue text">{ product }</h3>
                <Table className={'selection ' + product}>
                    {<Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="reference">Reference</Table.HeaderCell>
                                {headers[product].map((header) => (
                                    ( !header?.input ) && (
                                <Table.HeaderCell className={header?.key} key={ header?.label }>{ header?.label }</Table.HeaderCell>
                                )))}
                                <Table.HeaderCell className="amount" key="amount">
                                <input type="button" onClick={(event)=>handleClear(event, product)} value="X" title="clear all" className="del-btn"/>
                                 Amount</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>}
                    {<Table.Body>
                            {items?.map((item, row) => (
                                  <Table.Row key={ row }>
                                      <Table.Cell key="ref"><input type="text" name="reference" value={item?.reference} onChange={(event)=>handleChange(event, product, row)} className="reference" /> </Table.Cell> 
                                    {headers[product].map((header, index) => (
                                        ( !header?.input ) && (
                                      <Table.Cell key={row + ' ' + index}>{ item[header.key] }</Table.Cell> 
                                )))}
                                      <Table.Cell key="amount">
                                          <input type="button" onClick={(event)=>handleRowDel(event, product, row)} value="X" title="delete" className="del-btn"/>
                                          <input type="number" name="amount" value={item?.amount} onChange={(event)=>handleChange(event, product, row)} className="number" /> </Table.Cell> 
                                  </Table.Row>
                            ))}
                    </Table.Body>}
                </Table>
                <CSVLink className="ui button add blue" data={items} headers={headers[product]} filename="selection.csv" onClick={(event) => download({product, items})}>
                    Download Selection as csv</CSVLink> 
            </form>
            ))}
            </>

        )
    } else {
        return (  <Button className="ui button add blue" onClick={() => selectionButton(true)}>Show Selection ({ Object.values(selection.items).reduce((a, e)  => a + e.length, 0) })</Button> )
    }
}


