import { ConditionalLink, UniversalLink, Icon } from '@plone/volto/components';
import { getContentIcon } from '@plone/volto/helpers';


export function ContentLink({ doc }){
    return (
           <UniversalLink key={ doc['@id'] } className="ui raised segments document indent related" item={doc}>
                   <Icon name={getContentIcon(doc['@type'], doc?.is_folderish)}
                                            size="20px"
                                            className="icon-margin"
                                            title={doc['@type']} />{' '}
                               {doc.title}
          </UniversalLink>
    )
    }

export function RelateList({ doc }){
    return (
           <div key={ doc['@id'] } className="ui raised segments related indent" item={doc}>
                                {doc['@type']} 
               {' : '}
                                {doc.title}
          </div>
    )
    }

export function RelatedItems({ related }){
    if ( related?.length > 0 ){
    return (
        <div className="related-items">
            Related: { related.map((item) => ( <RelateList key={item["@type"]} doc={ item } />))}
        </div>
        )
    } else { return null; }
      
}
