import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createPortal } from 'react-dom';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConditionalLink, Component, Popup, Modal, UniversalLink, Icon, Toast } from '@plone/volto/components';
import { getBaseUrl, flattenToAppURL, getContentIcon } from '@plone/volto/helpers';
import { toast } from 'react-toastify';

import { Container, Image, Button, Header, List, ListItem, Table } from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { getContent } from '@plone/volto/actions';
import { getCalculator } from '../../../../../actions';
import { getDiffuser } from '../../../../../actions';
import { useSessionStorage } from '../../../../../helpers';
import { SelectionTable, SelectionStorage } from '../../../../../selection/table';
import { GeneratePdfForm } from '../../../../../selection/document';
import { Acoustic } from '../../../../../selection/acoustic';
import { ShowMore, ListContent } from '../../../../../selection/listcontent';
import { RelatedItems, ContentLink } from '../../../../../selection/relateditems';
import { ListMulti, ShowMulti, VariantMulti, VariantTableNew, VariantTable } from '../../../../../selection/variant';
import { trackEvent } from '@eeacms/volto-matomo/utils';
import { isEqual } from 'lodash';
import moment from 'moment';

class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputSubmit = this.handleInputSubmit.bind(this);
    }

    handleInputChange(event) {    
        const target = event.target;
        const value = target.value;
        const name = target.name;
        trackEvent({category: 'performance', action: "diffuser", name: name, value: value});
        this.props.setPerformance(
            {
             ...this.props.performance,
             [name]: value
            }
        );
    }

    handleInputSubmit(event) {
        const options = {category: 'calculate', action: 'max_pressure=' + this.props.performance.max_pressure + ' max_nc=' + this.props.performance.max_nc + ' volume=' + this.props.performance.volume };
        // trackEvent(options); 
        event.preventDefault();
    }

    render() {
        return (
            <form className="calculator" onSubmit={this.handleInputSubmit}>       
                <label>Volume: <DebounceInput debounceTimeout={500} type="number" name="volume" value={this.props.performance.volume || ''} onChange={this.handleInputChange} />l/s</label>
                <label>Maximum Pressure: <DebounceInput debounceTimeout={500} type="number" name="max_pressure" value={this.props.performance.max_pressure || ''} onChange={this.handleInputChange} />Pa</label>
                <label>Maximum NC: <DebounceInput debounceTimeout={500} type="number" name="max_nc" value={this.props.performance.max_nc || ''} onChange={this.handleInputChange} /></label>
            </form>
        );
    }
}

function ShowCalculationNew({ performance, calculator, item_id, variant, setVariant, table, setTable }) {
    const searchParams = new URLSearchParams(document.location.search)
    const dispatch = useDispatch();
    const [diffuser, setDiffuser] = useState({})
    if (searchParams.get('debug') ) {
        console.log('ShowCalculationNew', item_id, diffuser, variant, table);
    }

    const [last, setLast] = useState();
    const diffuser_variant = useSelector(state => state.diffuser?.result);

    const [diffuser_id, setDiffuserId] = useState(item_id);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const prefs = { ...performance,
                   type: "diffuser_variant",
                   uids: [],
                   variant: {...variant},
                   diffuser_id: diffuser_id,
                   new_calculator: true}
        dispatch(
            getDiffuser(diffuser_id, prefs));
    }, [dispatch, performance, variant]);

    // this is a bit hacky. Can't work out how to make useSelect "stateful"
    useEffect(() => {
        if (diffuser_variant?.diffuser?.diffuser_id === diffuser_id){
            setDiffuser({...diffuser_variant});
        }
    }, [diffuser_variant]);

    useEffect(() => {
        if (variant){
            const record = diffuser?.diffuser?.table[variant?.variant?.value];
            setTable({...record});
        } else if (diffuser){
            setTable({...diffuser?.diffuser?.table[diffuser?.diffuser?.selected_variant['variant'].value]});
        }
    }, [variant, diffuser]);

    function updateUserNumber({option, item}){
        setVariant({'variant' : {...diffuser?.diffuser?.selected_variant['variant']},
                    ...variant,
                    [option]: item});
    }
    
    function user_field(field){
        if ((table?.flow_dimensions || []).includes(field)){
            return 'user_' + field;
        } else {
            return field;
        }
    }
    useEffect(() => {
        if (variant !== undefined ){
            const temp = {...variant,
                          variant: variant?.variant?.label,
            }
            if ( variant?.type?.label !== undefined ){
                temp['type'] = variant?.type?.label;
            };
            if ( !isEqual(last, temp) ){
                const fields  = Object.keys(temp).sort().filter(key => !['user_core_area', 'variant'].includes(key)).map(key => key + "=" + temp[key]);
                trackEvent({category: 'selection', action: temp.variant, name: fields.join(', ')});
            }
            setLast(temp);
        }
    }, [variant])
    

    return (
            <>
                <div className="select_options">
                { (diffuser?.diffuser?.models?.length ) && (
                    <Select className={'variant'}
                            key={'variant'}
                            name={ 'variant' }
                            options={ diffuser?.diffuser?.models }
                            defaultValue={ diffuser?.diffuser?.models.filter(item => (item?.checked))[0] }
                            onChange={ (item) => setVariant({...diffuser?.diffuser?.selected_variant,
                                                             ...variant,
                                                             ['variant']: item})}
                            menuPlacement="auto"
                    /> )}
                    { (table?.flow_options?.map((option) => (
                     table?.options[option].length > 1  && (
                    <Select className={option}
                            key={option}
                            name={ option }
                            options={ table?.options[option] }
                            defaultValue={ table?.options[option].filter(item => (item?.checked))[0] }
                            onChange={ (item) => setVariant({...diffuser?.diffuser?.selected_variant,
                                                             ...variant,
                                                             [option]: item})}
                            menuPlacement="auto"
                    />
                    )
                )))}
                { (table?.flow_dimensions?.filter(option => option === 'core_areax').map((option) => (
                    <p key={option}>{'Core area (' + table?.min_max?.Minimum?.[option] + 'm2 - ' + table?.min_max?.Maximum?.[option] + 'm2)'}</p> 
                    )
                ))}
                    { (table?.dimensions || []).concat(table?.flow_dimensions || []).sort((a, b) => b > a).filter(option => option !== 'core_area').map((option) => (
               <label key={option}>{option + ' (' + table?.min_max?.Minimum?.[option] + 'mm - ' + table?.min_max?.Maximum?.[option] + 'mm)'}: <DebounceInput debounceTimeout={500} type="number" name={option} 
                            value={table?.min_max?.Minimum?.[option]}
                            min={table?.min_max?.Minimum?.[option]}
                            max={table?.min_max?.Maximum?.[option]}
                            onChange={ (item) => setVariant({...diffuser?.diffuser?.selected_variant,
                                                             ...variant,
                                                             [user_field(item.target.name)]: item.target.value})}
                /></label>
                    ))}
                </div>
                <h4 className="performance table">Performance Table</h4>
                <div className="top-list listing-performance">
                    <table className="listing performance">
                        <tbody>
                    <VariantTableNew item={table} />
                        </tbody>
                    </table>
                </div>
            </>
        )
}    

function ShowCalculation({ performance, calculator, item_id, variant, setVariant, table, setTable }) {
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug') ) {
        console.log('ShowCalculation', item_id, calculator, variant, table, performance);
    }
    const [checked, setChecked] = useState(calculator?.checked);
    if ( calculator?.checked && ! checked ){
        setChecked(calculator.checked);
    }
    const [last, setLast] = useState();

    useEffect(() => {
            setVariant(checked);

    }, [checked]); 

    useEffect(() => {
          setTable(calculator?.variants?.table.filter(item => (
             calculator?.option_order.filter(option => (variant?.[option] == item[option])).length == calculator?.option_order.length ))[0]);

    }, [variant, calculator, performance]);

    useEffect(() => {
        const temp = {...variant};
        if (variant !== undefined ){
            if ( last !== undefined &&  !isEqual(last, temp) ){
                const fields  = Object.keys(variant).sort().filter(key => key != 'variation').map(key => key + "=" + variant[key]);
                trackEvent({category: 'selection', action: variant.variation, name: fields.join(', ')});
            }
            setLast(temp);
        }
    }, [variant])

    return (
            <>
                <div className="select_options">
                {(calculator?.option_order) && (calculator?.option_order.map((option) => (
                     calculator?.options[option].length > 1  && (
                    <Select key={option}
                            name={ option }
                            options={ calculator?.options[option] }
                            defaultValue={ calculator?.options[option].filter(item => (item?.checked))[0] }
                            onChange={ (item) => setVariant({...variant, [option]: item.value})} />
                    )
                )))}
                </div>
                <h4 className="performance table">Performance Table</h4>
                <div className="top-list listing-performance">
                    <table className="listing performance">
                        <tbody>
                    <VariantTable item={table} />
                        </tbody>
                    </table>
                </div>
            </>
        )
}    

function AddSelection({selection, setSelection, calculator, model, variant, table}) {
    const fields = ['flow_rate', 'flow_rate_side_a', 'flow_rate_side_b', 
                    'static_pressure',
                    'horizontal_throw_0.25', 'vertical_throw_0.25',
                    'side_a_throw_0.25', 'side_b_throw_0.25',
                    'throw_0.25', 'spread_0.25', 'nc'];
    const searchParams = new URLSearchParams(document.location.search)
    const attributes = table?.flow_option?.type || table?.description || '';
    function addToSelection() {
        toast.success(
      <Toast
        success
        title={"This item has been added to your selection list"}
        content=""
      />,
    );
    
    trackEvent({category: 'selection', action: "add", name: (table?.title || item?.title)});
    setSelection({...selection,
                  items:{...selection?.items,
                         'diffuser':[...selection?.items?.diffuser,
             {id: selection?.items?.diffuser.length + 1,
              amount: 1,
              reference: '',
              model: table?.title || variant?.title ,
              attributes:  attributes,
              nominal_size: calculator?.calculator?.nominal_size,
              sections: calculator?.calculator?.sections,
              ...rec,
             }]}})
    }
    if (searchParams.get('debug')){
        console.log("Add Selection", selection, calculator, model, variant, table);
    }
    let rec = {};
    for (const line of table?.performance || []) {
         if (fields.includes(line?.['field_name'])){
             rec[line['field_name']] = line['value'] + ' ' + (line['units'] || '');
         }
         if (line?.['field_name'] === 'Nominal (w x h)'){
             rec['nominal_size'] = line['value'] + ' ' + (line['units'] || '');
         }
         if (line?.['field_name'] === 'Nominal Diameter'){
             rec['nominal_size'] = 'Diameter ' + line['value'] + ' ' + (line['units'] || '');
         }
         if (line?.['field_name'] === 'Overall (w x h)'){
             rec['overall_size'] = line['value'] + ' ' + (line['units'] || '');
         }
         if (line?.['field_name'] === 'Overall Diameter'){
             rec['overall_size'] = 'Diameter ' + line['value'] + ' ' + (line['units'] || '');
         }
    }


    return (
            <Button className="ui button add orange" title="Add item to selection" onClick={ addToSelection }>+</Button>
    )
}

function Variant({ performance, calculator, item, selection, setSelection, isEditMode}){
    const [variant, setVariant] = useState();
    const [table, setTable] = useState({});
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("Variant", item['@id'], calculator);
    }
    return (
        <>
                        { ( calculator?.variants?.dynamic ) ? (
                        <ShowCalculationNew 
                            performance={ performance }
                            calculator={ calculator }
                            item_id={ item['@id'] }
                            variant={variant}
                            setVariant={setVariant}
                            table={table}
                            setTable={setTable}
                            />
                        ) : (
                        <ShowCalculation performance={ performance } calculator={ calculator } item_id={ item['@id'] }
                            variant={variant} setVariant={setVariant} table={table} setTable={setTable} />
                        )}
                        <Acoustic calculator={calculator?.calculator}/>
                        <RelatedItems related={ calculator?.related } />
                        <div className="top-list listing-button">
                        <AddSelection selection={ selection } setSelection={ setSelection } calculator={ calculator } model={ item?.title } variant={variant} table={table} />
                        <ConditionalLink key={item.UID} className="ui button add blue notanon" title="Edit"
                            condition={!isEditMode} href={flattenToAppURL(item['@id'] + '/edit')}>Edit</ConditionalLink>
                        <GeneratePdfForm item={ item } calculator={ calculator } performance={ performance } table={table} selection={selection} setSelection={setSelection} />
                        <ShowMulti item={ item } calculator={ calculator } table={ table } variant={ variant } />
                        <ShowMore item={ item } calculator={ calculator } />
                    </div>
           </>)

}


const DiffuserTemplate = ({ items, linkTitle, linkHref, isEditMode, popup }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';
    if (isInternalURL(href)) {
        link = (
            <ConditionalLink className="ui raised segments modal" to={flattenToAppURL(href)} condition={!isEditMode}>
            {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a className="ui raised segments modal" href={href}>{linkTitle || href}</a>;
    }
    const token = useSelector((state) => state.userSession.token, shallowEqual);

    const [performance, setPerformance] = useSessionStorage('diffuser_performance', {width: undefined, height: undefined, diameter: undefined, volume: undefined, mode: undefined });
    const [selection, setSelection] = SelectionStorage();

    const dispatch = useDispatch();
    const calculator = useSelector((state) => state.calculator?.result);
    const uids = items.map(item => item.UID);

    const prefs = { ...performance,
                   type: "diffuser",
                   uids: uids,
                   new_calculator: true}
    const location = useLocation();
    useEffect(() => {
        dispatch(
            getCalculator(location.pathname, prefs));
    }, [dispatch, performance]);
  
    return (
        <>
            <NameForm performance={ performance } setPerformance={ setPerformance } />
            <SelectionTable selection={ selection } setSelection={ setSelection } />
            <div className="items summaryplus modals active visible dimmable dimmed">
                {items.map((item) => (
                    <div className="ui listing-body left three item-list" key={item['@id']}>
                        <div className="top-list listing-image">
                            <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                        </div>
                        <div className="top-list listing-contents">
                             {token? (<a href={flattenToAppURL(item['@id'])}><h2 className="orange text heading underline">{item.title || item.id}</h2></a> ): (
                            <h2 className="blue text heading">{item.title || item.id}</h2> ) }
                            <h3 className="product desc blue text">{item.description}</h3>
                        </div>
                        <Variant performance={ performance } calculator={ calculator?.[item.UID] } item={ item }
                        selection={ selection}
                        setSelection={setSelection}
                        isEditMode={isEditMode}/>
                        </div>
                ))}
            </div>
            {link && <div className="footer">{link}</div>}
        </>
    );
};

DiffuserTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default DiffuserTemplate;
