import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createPortal } from 'react-dom';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConditionalLink, Component, Popup, Modal, UniversalLink, Icon, Toast } from '@plone/volto/components';
import { getBaseUrl, flattenToAppURL, getContentIcon } from '@plone/volto/helpers';
import { toast } from 'react-toastify';

import { Container, Image, Button, Header, List, ListItem, Table } from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { getContent } from '@plone/volto/actions';
import { getCalculator } from '../../../../../actions';
import { getDamper } from '../../../../../actions';
import { useSessionStorage } from '../../../../../helpers';
import { SelectionTable, SelectionStorage } from '../../../../../selection/table';
import { GeneratePdfForm } from '../../../../../selection/document';
import { Acoustic } from '../../../../../selection/acoustic';
import { ShowMore, ListContent } from '../../../../../selection/listcontent';
import { RelatedItems, ContentLink } from '../../../../../selection/relateditems';
import { ListMulti, ShowMulti, VariantMulti, VariantTableNew, VariantTable } from '../../../../../selection/variant';
import { isEqual } from 'lodash';

import { trackEvent } from '@eeacms/volto-matomo/utils';
import moment from 'moment';

class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputSubmit = this.handleInputSubmit.bind(this);
    }

    handleInputChange(event) {    
        const target = event.target;
        const value = target.value;
        const name = target.name;
        trackEvent({category: 'performance', action: "damper", name: name, value: value});
        this.props.setPerformance(
            {
             ...this.props.performance,
             [name]: value
            }
        );
    }

    handleInputSubmit(event) {
        const options = {category: 'calculate', action: 'width=' + this.props.performance.width + ' height=' + this.props.performance.height + ' volume=' + this.props.performance.volume };
        // trackEvent(options); 
        event.preventDefault();
    }

    render() {
        return (
            <form className="calculator" onSubmit={this.handleInputSubmit}>       
                <label>Width: <DebounceInput debounceTimeout={500} type="number" name="width" value={this.props.performance.width || ''} onChange={this.handleInputChange} />mm</label>
                <label>Height: <DebounceInput debounceTimeout={500} type="number" name="height" value={this.props.performance.height || ''} onChange={this.handleInputChange} />mm</label>
                <label>Volume: <DebounceInput debounceTimeout={500} type="number" name="volume" value={this.props.performance.volume || ''} onChange={this.handleInputChange} />l/s</label>
            </form>
        );
    }
}


function ShowCalculation({ performance, calculator, item_id, variant, setVariant, table, setTable, rating, setRating, application, setApplication }) {

    const [last, setLast] = useState();

    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug') ) {
        console.log('ShowCalculation', item_id, calculator, variant, table, performance);
    }
    const [checked, setChecked] = useState(calculator?.checked);
    if ( calculator?.checked && ! checked ){
        setChecked(calculator.checked);
    }
    
    useEffect(() => {
            setVariant({...checked,
                        load_bearing: 'yes'});
            setRating({value: checked?.rating, label: checked?.rating + ' (min)'});
            setApplication({value: checked?.application, label: checked?.application});
    }, [checked]); 

    useEffect(() => {
             setTable(calculator?.variants?.table.filter(item => (
             calculator?.option_order.filter(option => (variant?.[option] == item[option])).length == calculator?.option_order.length ))[0]);

    }, [variant, calculator, performance]);

    useEffect(() => {
            if ( table?.application_order?.includes(application?.value) ){
                setApplication({value: application?.value, label: application?.value});
            } else { 
                if (table?.application_order?.length > 0){
                    const app = table?.application_order[0];
                    setApplication({value: app, label: app});
                }
            }
    }, [table]);

    useEffect(() => {
        if (table?.ratings && Object.keys(table.ratings).includes(application?.value) && table.ratings[application.value].filter((item) => item.value == rating.value).length == 0){
                const value = table.ratings[application.value].filter((item) => item?.checked)[0].value;
                setRating({value: value, label: value + ' (min)'});
            }
    }, [application])


    useEffect(() => {
        const temp = {variant: {...variant}, application: {...application}, rating: {...rating}};
        if (variant !== undefined && application?.value !== undefined ){
            if ( last !== undefined &&  !isEqual(last, temp) ){
                const unordered = {...variant, application: application.value, rating: rating.label};
                const fields  = Object.keys(unordered).sort().filter(key => key != 'variation').map(key => key + "=" + unordered[key]);
                trackEvent({category: 'selection', action: variant.variation, name: fields.join(', ')});
            }
            setLast(temp);
        }
    }, [variant, application, rating])

    
    const distance = 'distance';

    return (
            <>
                {(calculator?.option_order)
                  && (

                    calculator?.option_order.map((option, index) => (
                     calculator?.options[option].length > 1  && (
                <div key={index} className="select_options">
                    <Select className={option}
                            key={option}
                            name={ option }
                            options={ calculator?.options[option] }
                            defaultValue={ calculator?.options[option].filter(item => (item?.checked))[0] }
                            onChange={ (item) => setVariant({...variant, [option]: item.value})} />
                </div>
                     ))))}
                <div className="select_options">
                {(table?.application_order?.includes(application.value)
                  && table?.application) && (
                    <Select className="application"
                            key="application"
                            name="application"
                            options={ table?.application }
                            value={ application } 
                            onChange={ (item) => setApplication(item) } />
                    )
                }
                {(table?.application_order?.includes(application.value)
                  && table?.ratings?.[application.value]) && (
                    <Select className="rating"
                            key="rating"
                            name="rating"
                            options={ table?.ratings[application.value]}
                            value={ rating }
                            onChange={ (item) => setRating(item)} />
                    )
                }

                </div>
                {(item_id === "/dampers/lr" && ['Plasterboard Wall', 'Plasterboard Wall (Steel Framed)', 'Timber Framed Floor'].includes(application?.value)) && (
                    <>
                    <label key={distance}>Distance to framing:
                        <DebounceInput
                                debounceTimeout={500}
                                type="number"
                                name={distance} 
                                value={0}
                                min={0}
                                onChange={ (item) => setVariant({...variant,
                                                                 [distance]: item.target.value})} />mm
                        </label>
                    {( application.value.includes('Wall')) && (

                    <Select className={"load_bearing"}
                            key={"load_bearing"}
                            name={"load_bearing"}
                            options={ [{"value": "yes", "label": "Load Bearing"}, {"value": "no", "label": "Non-Load Bearing"}] }
                            defaultValue={ {"value": "yes", "label": "Load Bearing"} }
                            onChange={ (item) => setVariant({...variant, ["load_bearing"]: item.value})} />
                        )}
                    </>
                            )}
                { (table?.application_order?.includes(application.value)
                   && rating.value) && (
                    table?.ratings?.[application.value].filter( (item) => (item.value == rating.value)).map( (item, index) => (
                    <div className="construction" key={index}>
                        <h4>{application.value} Fire Construction {rating.value} minutes</h4>
                        <p>{item.construction}</p>
                    </div>
                    )))
                }
                { (table?.aperture_order?.includes(application.value)
                   && rating.value) && (
                    table?.aperture?.[application.value].filter( (item) => (item.rating == rating.value && (variant?.distance || 0) >= item.min && (variant?.distance || 0) <= item.max && (item?.load_bearing === 'both' || item?.load_bearing === variant?.load_bearing))).map( (item, index) => (
                    <p key={ index }>{item.construction}</p>
                    )))}
                <h4 className="performance table">Performance Table</h4>
                <div className="top-list listing-performance">
                    <table className="listing performance">
                        <tbody>
                    <VariantTable item={table} />
                        </tbody>
                    </table>
                </div>
            </>
        )
}    

function AddSelection({selection, setSelection, calculator, model, variant, table}) {
    const fields = ['nominal_size', 'sections', 'penetration_size', 'overall_size', 'flow_rate', 'free_area_velocity', 'free_area', 'free_area_pc', 'pressure_drop', 'estimated_weight'
                    ];
    const searchParams = new URLSearchParams(document.location.search)
    const attributes = table?.flow_option?.type || table?.description || '';
    function addToSelection() {
        toast.success(
      <Toast
        success
        title={"This item has been added to your selection list"}
        content=""
      />,
    );
    if (searchParams.get('debug')){
        console.log("Add Selection", selection, calculator, model, variant, table);
    }
    let rec = {};
    for (const line of table?.performance || []) {
         if (fields.includes(line?.['field_name'])){
             let value = line['value'] + ' ' + (line['units'] || '');
             if (line?.['extra']){
                 value = line['extra'] + ' ' + value
             }
             rec[line['field_name']] = value;
         }
    }
    trackEvent({category: 'selection', action: "add", name: (table?.title || item?.title)});
    setSelection({...selection,
                  items:{...selection?.items,
                         'damper':[...selection?.items?.damper,
             {id: selection?.items?.damper.length + 1,
              amount: 1,
              reference: '',
              model: table?.title || variant?.title ,
              attributes:  attributes,
              ...rec,
             }]}})
    }


    return (
            <Button className="ui button add orange" title="Add item to selection" onClick={ addToSelection }>+</Button>
    )
}

function Variant({ performance, calculator, item, selection, setSelection, isEditMode}){
    const [variant, setVariant] = useState();
    const [table, setTable] = useState({});
    const [rating, setRating] = useState();
    const [application, setApplication] = useState();
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')) {
        console.log("Variant", item['@id'], performance, calculator);
    }
    return (
        <>
                        <ShowCalculation
                            performance={ performance }
                            calculator={ calculator }
                            item_id={ item['@id'] }
                            variant={variant}
                            setVariant={setVariant}
                            table={table}
                            setTable={setTable}
                            rating={rating}
                            setRating={setRating}
                            application={application}
                            setApplication={setApplication}
                            />
                        <Acoustic calculator={calculator?.calculator}/>
                        <RelatedItems related={ calculator?.related } />
                        <div className="top-list listing-button">
                        <AddSelection
                            selection={ selection }
                            setSelection={ setSelection }
                            calculator={ calculator }
                            model={ item?.title }
                            variant={variant} table={table} />
                        <ConditionalLink
                            key={item.UID}
                            className="ui button add blue notanon"
                            title="Edit"
                            condition={!isEditMode}
                            href={flattenToAppURL(item['@id'] + '/edit')}>
                            Edit
                        </ConditionalLink>
                        <GeneratePdfForm
                            item={ item }
                            calculator={ calculator }
                            performance={ performance }
                            table={table}
                            selection={selection}
                            setSelection={setSelection}
                            variant={variant}
                            rating={rating}
                            application={application}
                            />
                       { false && <ShowMulti item={ item } calculator={ calculator } table={ table } variant={ variant } />}
                        <ShowMore item={ item } calculator={ calculator } />
                    </div>
           </>)

}


const DamperTemplate = ({ items, linkTitle, linkHref, isEditMode, popup }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';
    if (isInternalURL(href)) {
        link = (
            <ConditionalLink className="ui raised segments modal" to={flattenToAppURL(href)} condition={!isEditMode}>
            {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a className="ui raised segments modal" href={href}>{linkTitle || href}</a>;
    }
    const token = useSelector((state) => state.userSession.token, shallowEqual);

    const [performance, setPerformance] = useSessionStorage('damper_performance',
                                                            {width: undefined,
                                                             height: undefined,
                                                             diameter: undefined,
                                                             volume: undefined,
                                                             mode: undefined });
    const [selection, setSelection] = SelectionStorage();

    const dispatch = useDispatch();
    const calculator = useSelector((state) => state.calculator?.result);
    const uids = items.map(item => item.UID);
  
    const searchParams = new URLSearchParams(document.location.search)
    const prefs = { ...performance,
                   type: "damper",
                   uids: uids,
                   new_calculator: true}
    const location = useLocation();
    useEffect(() => {
        dispatch(
            getCalculator(location.pathname, prefs));
    }, [dispatch, performance]);
    if (searchParams.get('debug')) {
        console.log("Template", performance, calculator);
    }
  
    return (
        <>
            <NameForm performance={ performance } setPerformance={ setPerformance } />
            <SelectionTable selection={ selection } setSelection={ setSelection } />
            <div className="items summaryplus modals active visible dimmable dimmed">
                {items.map((item) => (
                    <div className="ui listing-body left three item-list" key={item['@id']}>
                        <div className="top-list listing-image">
                            <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                        </div>
                        <div className="top-list listing-contents">
                             {token? (<a href={flattenToAppURL(item['@id'])}><h2 className="orange text heading underline">{item.title || item.id}</h2></a> ): (
                            <h2 className="blue text heading">{item.title || item.id}</h2> ) }
                            <h3 className="product desc blue text">{item.description}</h3>
                        </div>
                        <Variant performance={ performance } calculator={ calculator?.[item.UID] } item={ item }
                        selection={ selection}
                        setSelection={setSelection}
                        isEditMode={isEditMode}/>
                        </div>
                ))}
            </div>
            {link && <div className="footer">{link}</div>}
        </>
    );
};

DamperTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default DamperTemplate;
