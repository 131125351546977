import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, Header, List, ListItem } from 'semantic-ui-react';
import { UniversalLink, Icon } from '@plone/volto/components';
import { flattenToAppURL, getContentIcon } from '@plone/volto/helpers';
import { Acoustic } from './acoustic';
import { trackEvent } from '@eeacms/volto-matomo/utils';

export function ShowMore({item, calculator}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button className="ui button add blue" onClick={() => setShowModal(true)}>View More</Button>
            {showModal && createPortal(

                <ListContent item={item} calculator={calculator} onClose={() => setShowModal(false)} />,
                document.body
            )}
        </>
    )
}

export function ListContent ({ onClose, item, calculator }) {
    let orientations = new Set(['Horizontal', 'Vertical']);
    let application = item?.Subject?.filter(i => (! orientations.has(i))) || [];
    const options = {category: item['@id'], action: 'view-more'};
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')){
        console.log("List content", calculator?.calculator);
    }
    
    trackEvent({category: 'performance', action: "info", name: item?.title});
 
    return (
        <div className="ui page modals dimmer transition visible active">
            <div className="ui modal transition visible active modal">
                <div className="actions">
                    <Button className="ui basic circular floated button orange" aria-label="Close" title="close" onClick={onClose}>Close</Button>
                </div>
                <div className="ui header">
                    <h1 className="center">{item.title}</h1>
                </div>
                <div className="content popup">
                    <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                    <div className="inside-popup">
                        <h1 className="diffuserdesc">{item.description}</h1>
                        <p>Overview: {calculator?.overview}</p>
                                               {application.length > 0 && (
                            <>
                                <Header dividing sub>Diffuser applications</Header>
                                <List items={ application } />
                            </>
                        )}

                        <Acoustic calculator={calculator?.calculator}/>

                        {calculator?.documents.length > 0 && (
                          <>
                              <List>
                          {calculator.documents.map((doc) => (
                              <ListItem key={doc['@id']}>
              
                                  <UniversalLink className="ui raised segments document" item={doc}>
                                      <Icon name={getContentIcon(doc['@type'], doc?.is_folderish)}
                                            size="20px"
                                            className="icon-margin"
                                            title={doc['@type']} />{' '}
                                      {doc.title}
                                  </UniversalLink>
                              </ListItem>
                          ))}
                              </List>
                          </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
