import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConditionalLink, Component, Popup, Modal, UniversalLink, Icon } from '@plone/volto/components';
import { getBaseUrl, flattenToAppURL, getContentIcon } from '@plone/volto/helpers';

import { Container, Image, Button, Header, List, ListItem, Table } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { getContent } from '@plone/volto/actions';
import { getCalculator } from '../../../../../actions';
import { useSessionStorage } from '../../../../../helpers';
import { SelectionTable, SelectionStorage } from '../../../../../selection/table';
import { GeneratePdfButton } from '../../../../../selection/document';
//import { trackEvent } from '@eeacms/volto-matomo/utils';
import moment from 'moment';

class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputSubmit = this.handleInputSubmit.bind(this);
    }

    handleInputChange(event) {    
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.setPerformance(
            {
             ...this.props.performance,
             [name]: value
            }
        );
    }

    handleInputSubmit(event) {
        const options = {category: 'calculate', action: 'width=' + this.props.performance.width + ' height=' + this.props.performance.height + ' volume=' + this.props.performance.volume };
        // trackEvent(options); 
        event.preventDefault();
    }

    render() {
        return (
            <form className="calculator" onSubmit={this.handleInputSubmit}>       
                <label>Width: <input type="number" name="width" value={this.props.performance.width || ''} onChange={this.handleInputChange} />mm</label>
                <label>Height: <input type="number" name="height" value={this.props.performance.height || ''} onChange={this.handleInputChange} />mm</label>
                <label>Volume: <input type="number" name="volume" value={this.props.performance.volume || ''} onChange={this.handleInputChange} />l/s</label>
            </form>
        );
    }
}

function Information({button, message}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <div className="top-list listing-button">
            <Button className="ui button add blue" onClick={() => setShowModal(true)}>{ button }</Button>
            {showModal && createPortal(

                <ListContent calculator={calculator} onClose={() => setShowModal(false)} />,
                document.body
            )}
        </div>
    )
}
const ListContent = ({ onClose, item, calculator }) => {
    let orientations = new Set(['Horizontal', 'Vertical']);
    let features = item.Subject.filter(i => (! orientations.has(i)));
    const options = {category: item['@id'], action: 'view-more'};
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')){
        console.log(calculator?.calculator);
    }
    // trackEvent(options); 
    return (
        <div className="ui page modals dimmer transition visible active">
            <div className="ui modal transition visible active modal">
                <div className="actions">
                    <Button className="ui basic circular floated button orange" aria-label="Close" title="close" onClick={onClose}>Close</Button>
                </div>
                <div className="ui header">
                    <h1 className="center">{item.title}</h1>
                </div>
                <div className="content popup">
                    <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                    <div className="inside-popup">
                        <h1 className="louverdesc">{item.description}</h1>
                                               {features.length > 0 && (
                            <>
                                <Header dividing sub>Louver features</Header>
                                <List items={ features } />
                            </>
                        )}
                        <Acoustic calculator={calculator?.calculator}/>

                        {calculator?.documents.length > 0 && (
                          <>
                              <List>
                          {calculator.documents.map((doc) => (
                              <ListItem key={doc['@id']}>
              
                                  <UniversalLink className="ui raised segments document" item={doc}>
                                      <Icon name={getContentIcon(doc['@type'], doc?.is_folderish)}
                                            size="20px"
                                            className="icon-margin"
                                            title={doc['@type']} />{' '}
                                      {doc.title}
                                  </UniversalLink>
                              </ListItem>
                          ))}
                              </List>
                          </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Intake({ calculator }){
    const intake = calculator?.pressure_drop?.intake;
    if ( intake > calculator?.pressure_drop_max?.intake ){
        const pressure = "pressure exceeds permissible levels (" + calculator?.pressure_drop_max?.intake + " Pa) as " + calculator?.limits?.pressure_limitation;
        return (
            <>
                    <strong className="warn">{ intake } Pa</strong>
                    <Popup content={ pressure } trigger={<Button className="ui button warn">Limitation</Button>} /> 
            </>
    )}
    return ( <> { intake || '-' } Pa </> )
}

function AreaVelocity( { calculator }){
    if ( calculator?.louver_velocity > calculator?.limits?.velocity_max ){
        const velocity = "velocities above " + calculator?.limits?.velocity_max + "m/s could incur water carryover and increased noise";
        return (
            <>
                    <strong className="warn">{ calculator?.louver_velocity || '-' } m/s</strong>
                    <Popup content={ velocity } trigger={<Button className="ui button warn">Limitation</Button>} /> 
            </>
        )
    }
    return (
        <> { calculator?.louver_velocity || '-' } m/s </>
    )
}

function Acoustic({ calculator }){
    let columns = ['125Hz', '250Hz', '500Hz', '1kHz', '2kHz', '4kHz', '8kHz'];
    if ( calculator?.acoustic?.items.length > 0 ){
        return (
                    <>
                    <Header sub>Acoustic Performance</Header>
                    <Table className="acoustic">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Frequency</Table.HeaderCell>
                                      { calculator?.acoustic?.items.map((item) => (
                                    <Table.HeaderCell key={item["identifier"]}>{ item['identifier'] } (dB)</Table.HeaderCell> 
                                      ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {columns.map((column) => (
                                  <Table.Row key={column}>
                                    <Table.Cell key={column}>{ column }</Table.Cell> 
                                      { calculator?.acoustic?.items.map((item) => (
                                    <Table.Cell key={item["@id"]}>{ item[column].toLocaleString(undefined, {minimumFractionDigits:1}) }</Table.Cell> 
                                      ))}
                                  </Table.Row>
                                 ))}
                        </Table.Body>
                    </Table>
                    </>
      )
    } else { return null;
 }
}


function ShowCalculation({ performance, calculator, item_id }) {
    let perf = '-';
    let vol = '-';
    let table_dimension = 'listing performance';
    if (performance.width && performance.height){
        perf = performance.width + ' mm x ' + performance.height + ' mm';
        if (performance.volume){
            table_dimension = 'listing performance volume';
        } else {
            table_dimension = 'listing performance size';
        }

    }
    if (performance.volume){
        vol = performance.volume + ' l/s';
    }
    let efficiency_heading = 'Efficiency Class:';
    let efficiency = calculator?.efficiency_class || '-';
    if ( ! calculator?.efficiency_class && calculator?.water_penetration ){
        efficiency_heading = 'Water Penetration:';
        efficiency = calculator?.water_penetration + ' m/s';
    }
    if ( calculator?.errors ) {
        return (
            <>
                <h4 className="performance table">Performance Table</h4>
                <div className="top-list listing-performance">
                    <table className="listing performance">
                        <tbody>
                            <tr><td className="heading">Nominal Size:</td><td className="result">{ calculator.errors }</td></tr>
                            <tr><td className="heading">Airflow:</td><td className="result">{ vol }</td></tr>
                            <tr><td className="heading">{ efficiency_heading }</td><td className="result">{ efficiency }</td></tr>
                            <tr><td className="heading">Wind Loading:</td><td className="result">{ calculator?.wind_loading || '-' }</td></tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }


    const exhaust = calculator?.pressure_drop?.exhaust;
    const intake = calculator?.pressure_drop?.intake;
    return (
        <>
            <h4 className="performance table">Performance Table</h4>
            <div className="top-list listing-performance">
                <table className={ table_dimension }>
                    <thead>
                        <tr><th className="heading">Nominal Size:</th><th className="result">{ perf }</th></tr>
                        <tr><th className="heading">Airflow:</th><th className="result">{ vol }</th></tr>
                    </thead>
                    <tbody>
                        { (calculator?.width_sections * calculator?.height_sections) > 1 && (
                        <tr><td className="heading">Nominal Section ({calculator?.width_sections} x {calculator?.height_sections}) Size:</td><td className="result">{ calculator.section_width + ' mm x ' + calculator.section_height + ' mm' }</td></tr>
                        )}
                        { performance.volume > 0.0 && (
                            <>
                                <tr><td className="heading">Free Area Velocity:</td>
                                    <td className="result"><AreaVelocity calculator={calculator} /></td>
                                </tr>
                                <tr><td className="heading">Pressure Drop (intake):</td><td className="result"><Intake calculator={calculator} /></td></tr>
                                <tr><td className="heading">Pressure Drop (exhaust):</td><td className="result">{ exhaust || '-' } Pa</td></tr>
                            </>)
                        }
                        { performance?.width && performance?.height && (
                            <>
                                <tr><td className="heading">Free Area:</td><td className="result">{ calculator?.pressure_area || '-' } m<sup>2</sup></td></tr>
                                <tr><td className="heading">Free Area:</td><td className="result">{ calculator?.free_area || '-' } %</td></tr>
                                <tr><td className="heading">Estimated Weight:</td><td className="result">{ calculator?.unit_weight || '-' } kg</td></tr>
                            </>
                        )}
                                <tr><td className="heading">{ efficiency_heading }</td><td className="result">{ efficiency }</td></tr>
                                <tr><td className="heading">Wind Loading:</td><td className="result">{ calculator?.wind_loading || '-' }</td></tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}    

function AddSelection({selection, setSelection, calculator, model}) {
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('debug')){
        console.log(selection, calculator);
    }
    var estimated_weight = '';
    if ( calculator?.calculator?.unit_weight ){
         estimated_weight = calculator?.calculator?.unit_weight + ' kg';
    }
    var free_area_velocity = '';
    if ( calculator?.calculator?.louver_velocity ){
         free_area_velocity = calculator?.calculator?.louver_velocity + ' m/s';
    }
    var pressure_drop_intake = '';
    if ( calculator?.calculator?.pressure_drop?.intake ){
         pressure_drop_intake = calculator?.calculator?.pressure_drop?.intake + ' Pa';
    }
    var pressure_drop_exhaust = '';
    if ( calculator?.calculator?.pressure_drop?.exhaust ){
         pressure_drop_exhaust = calculator?.calculator?.pressure_drop?.exhaust + ' Pa';
    }
    var free_area = '';
    if ( calculator?.calculator?.free_area ){
         free_area = calculator?.calculator?.free_area + ' %';
    }
    return (
            <Button className="ui button add blue" title="Add selection" onClick={() => setSelection({items:[...selection?.items,
             {id: selection?.items.length + 1,
              amount: 1,
              reference: '',
              model: model,
              nominal_size: calculator?.calculator?.nominal_size,
              sections: calculator?.calculator?.sections,
              free_area_velocity: free_area_velocity,
              pressure_drop_intake: pressure_drop_intake,
              pressure_drop_exhaust: pressure_drop_exhaust,
              free_area: free_area,
              estimated_weight: estimated_weight,
              efficiency: calculator?.calculator?.efficiency,
              wind_loading: (calculator?.calculator?.wind_loading || '-')
             }]})}>+</Button>
    )
}


function ShowMore({item, calculator}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button className="ui button add blue" onClick={() => setShowModal(true)}>View More</Button>
            {showModal && createPortal(

                <ListContent item={item} calculator={calculator} onClose={() => setShowModal(false)} />,
                document.body
            )}
        </>
    )
}

const SummaryTemplate = ({ items, linkTitle, linkHref, isEditMode, popup }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';
  
    if (isInternalURL(href)) {
        link = (
            <ConditionalLink className="ui raised segments modal" to={flattenToAppURL(href)} condition={!isEditMode}>
            {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a className="ui raised segments modal" href={href}>{linkTitle || href}</a>;
    }
    const [performance, setPerformance] = useSessionStorage('performance', {width: undefined, height: undefined, volume: undefined});
    const [selection, setSelection] = SelectionStorage();

    const dispatch = useDispatch();
    const calculator = useSelector((state) => state.calculator?.result);
    const uids = items.map(item => item.UID);
  
    const prefs = { ...performance,
                   type: "louver",
                   uids: uids}
    const location = useLocation();
    useEffect(() => {
        dispatch(
            getCalculator(location.pathname, prefs));
    }, [dispatch, performance]);
  
    return (
        <>
            <NameForm performance={ performance } setPerformance={ setPerformance } />
            <SelectionTable selection={ selection } setSelection={ setSelection } />
            <div className="items summaryplus modals active visible dimmable dimmed">
                {items.map((item) => (
                    <div className="ui listing-body left three item-list" key={item['@id']}>
                        <div className="top-list listing-image">
                            <img className="PreviewImage" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/preview`,)} alt={item.title || item.id} />
                        </div>
                        <div className="top-list listing-contents">
                            <h1>{item.title || item.id}</h1>
                            <h1 className="louverdesc">{item.description}</h1>
                        </div>
                        <ShowCalculation performance={ performance } calculator={ calculator?.[item.UID]?.calculator } item_id={ item['@id'] } />
                        <Acoustic calculator={calculator?.[item.UID]?.calculator}/>
                        <div className="top-list listing-button">
                        <AddSelection selection={ selection } setSelection={ setSelection } calculator={ calculator?.[item.UID] } model={ item?.title } />
                        <GeneratePdfButton item={ item } calculator={ calculator?.[item.UID] } performance={ performance } />
                        <ShowMore item={ item } calculator={ calculator?.[item.UID] } />
                        </div>
                    </div>
                ))}
            </div>
            {link && <div className="footer">{link}</div>}
        </>
    );
};

SummaryTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default SummaryTemplate;
